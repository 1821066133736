import { createInjection } from 'src/util/createInjection';
import { DailyTaskData } from '../types';
import { useDisplayConditions } from './useDisplayConditions';
import { useSearchConditions } from './useSearchConditions';
import macroOperationApi from 'src/apis/macroOperation';
import { useNotification } from 'src/composables/useNotification';
import { isExist } from 'src/util/isExist';
import { useMonthlyPlanOverview } from './useMonthlyPlanOverview';
import { useMonthlyPlan } from './useMonthlyPlan';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { MacroOperationIndexResult } from 'src/models/macroOperation';
import { convertToHeadcount, convertToManHours } from '../monthlyPlanHelper';

const DATE_FORMAT = 'yyyy-MM-dd';

type InjectionValue = {
  macroOperationIndex: (dt: Date, budgetGroupId: number) => Promise<MacroOperationIndexResult>;
  updateDayActualData: (taskData: DailyTaskData, dayIndex: number, label: string) => Promise<void>;
};

const { provide, inject } = createInjection<InjectionValue>('useMacroOperation');

export function useMacroOperationProvider(): void {
  const { budgetGroup, workplaceId } = useSearchConditions();
  const { state: displayState } = useDisplayConditions();
  const { updateOverviewState } = useMonthlyPlanOverview();
  const { notifyError, notifySuccess } = useNotification();
  const { state, calculateDailyPlan } = useMonthlyPlan();

  const macroOperationIndex = async (dt: Date, budgetGroupId: number) => {
    const startDate = startOfMonth(dt);
    const endDate = endOfMonth(dt);

    return await macroOperationApi.macroOperationIndex({
      workplace_id: workplaceId,
      budget_group_id: budgetGroupId,
      start_date: format(startDate, DATE_FORMAT),
      end_date: format(endDate, DATE_FORMAT),
    });
  };

  /**
   * 月間計画の実績更新
   */
  const updateDayActualData = async (taskData: DailyTaskData, dayIndex: number, label: string) => {
    if (!budgetGroup.value) {
      return;
    }
    if (!isExist(state.isReady)) {
      return;
    }
    if (!isExist(taskData.taskId)) {
      return;
    }
    const taskIndex = state.tasks.findIndex((item) => item.id === taskData.taskId);
    const macroOperationMaster = state.tasks[taskIndex];
    if (!isExist(macroOperationMaster)) {
      return;
    }

    const task = state.dailyPlans[dayIndex].tasks[taskIndex];
    if (displayState.isDisplayedManHour) {
      task.actualHeadcount = convertToHeadcount(task.actualManHours);
      task.overtimeHeadcount = convertToHeadcount(task.overtimeWorkHours);
    } else {
      task.actualManHours = convertToManHours(task.actualHeadcount);
      task.overtimeWorkHours = convertToManHours(task.overtimeHeadcount);
    }

    try {
      const res = await macroOperationApi.upsert({
        workplace_id: workplaceId,
        budget_group_id: budgetGroup.value.id,
        dt: state.actualDates[dayIndex],
        actual_working_hours: taskData.actualManHours,
        overtime_work_hours: taskData.overtimeWorkHours,
        macro_operation_master_id: taskData.taskId,
      });
      const calculated = calculateDailyPlan(state.dailyPlans[dayIndex]);
      state.dailyPlans[dayIndex].totalActualManHours = calculated.totalActualManHours;
      updateOverviewState(state);
      notifySuccess(`${label}を更新しました`);
    } catch (err: any) {
      notifyError(`${label}の更新に失敗しました`, { cause: err });
    }
  };

  provide({
    macroOperationIndex,
    updateDayActualData,
  });
}

export function useMacroOperation(): InjectionValue {
  return inject();
}
