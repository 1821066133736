import { reactive, toRefs, InjectionKey, Ref } from '@vue/composition-api';
import { apisWithTransformedData as budgetGroupApi } from 'src/apis/budget_group';
import staffAgencyApi from 'src/apis/staffAgency';
import { SHIFT_PHASE, type ShiftPhase } from 'src/consts';
import { type MacroOperationMaster } from 'src/models/macroOperationMaster';
import macroOperationMasterApi from 'src/apis/workplace_masters/macro_operation_master';
import { type BudgetGroup } from 'src/models/budgetGroup';
import { type Master, type LovEntry } from 'src/models/master';
import { type StaffAgency } from 'src/models/staffAgency';

type State = {
  workplaceId: number;
  baseDate: Date;
  budgetGroup: BudgetGroup;
  attendanceTypes: LovEntry[];
  attendanceSupplements: LovEntry[];
  budgetGroups: BudgetGroup[];
  staffAgencies: StaffAgency[];
  displayMode: ShiftPhase;
  macroOperationMasters: MacroOperationMaster[];
  isLoading: boolean;
};

const initialBudgetGroupData: BudgetGroup = {
  budget_group_extension: {
    budget_group_id: 0,
    id: 0,
    use_regular_shift: true,
    is_revenue_save: false,
    is_cost_save: false,
    is_indirect_cost_save: false,
  },
  workplace_id: 0,
  disp_order: 0,
  id: 0,
  is_enabled: true,
  name: '',
};

type CommonState = {
  setWorkPlaceId: (workPlaceId: number | string) => void;
  changeBaseDate: (date: Date) => void;
  getMacroOperations: () => Promise<void>;
  waitLoading: () => void;
  finishLoading: () => void;
  initializeCommonState: (masters: Master) => Promise<void>;
  workplaceId: Ref<number>;
  baseDate: Ref<Date>;
  budgetGroup: Ref<BudgetGroup>;
  attendanceTypes: Ref<LovEntry[]>;
  attendanceSupplements: Ref<LovEntry[]>;
  budgetGroups: Ref<BudgetGroup[]>;
  staffAgencies: Ref<StaffAgency[]>;
  displayMode: Ref<ShiftPhase>;
  macroOperationMasters: Ref<MacroOperationMaster[]>;
  isLoading: Ref<boolean>;
};

export const useCommonState = (): CommonState => {
  const state = reactive<State>({
    workplaceId: 0,
    baseDate: new Date(),
    budgetGroup: initialBudgetGroupData,
    attendanceTypes: [],
    attendanceSupplements: [],
    budgetGroups: [],
    staffAgencies: [],
    displayMode: SHIFT_PHASE.PROVISIONAL,
    macroOperationMasters: [],
    isLoading: false,
  });

  const getMacroOperations = async () => {
    // シフトグループ一覧を取得
    state.macroOperationMasters = await macroOperationMasterApi.index({
      workplaceId: state.workplaceId,
      budgetGroupId: state.budgetGroup.id,
      params: {},
    });
  };

  const initializeCommonState = async (masters: Master) => {
    state.attendanceTypes = masters.lovs.attendance_type.vals_inuse;
    state.attendanceSupplements = masters.lovs.attendance_supplement.vals_inuse;

    const [budgetGroups, staffAgencies]: [BudgetGroup[], StaffAgency[]] = await Promise.all([
      budgetGroupApi.index(state.workplaceId),
      staffAgencyApi.index({ workplace_id: state.workplaceId }),
    ]);

    state.budgetGroups = budgetGroups;
    state.staffAgencies = staffAgencies;

    if (state.budgetGroups.length > 0) {
      state.budgetGroup = state.budgetGroups[0];
    }
  };

  const setWorkPlaceId = (workPlaceId: number | string): void => {
    state.workplaceId = Number(workPlaceId);
  };

  const changeBaseDate = (date: Date): void => {
    state.baseDate = date;
  };

  const waitLoading = (): void => {
    state.isLoading = true;
  };

  const finishLoading = (): void => {
    state.isLoading = false;
  };

  return {
    ...toRefs(state),
    setWorkPlaceId,
    changeBaseDate,
    initializeCommonState,
    getMacroOperations,
    waitLoading,
    finishLoading,
  };
};

export const CommonStateKey: InjectionKey<CommonState> = Symbol('CommonState');
