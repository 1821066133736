import { ref, computed, watch, type Ref, type ComputedRef } from '@vue/composition-api';
import { timeStrToTimeInteger } from 'src/util/datetime';
import { createInjection } from 'src/util/createInjection';
import { useModalWithContext, type ModalWithContext } from 'src/composables/useModalWithContext';
import type { TimeBlock } from '../../types';
import { getNextIntervalUnitMinutes } from '../../dailySimulationHelper';
import HeadcountUpdateNotification from '../../components/UpdateNotification/HeadcountUpdateNotification.vue';
import { useNotification } from './composables/useNotification';

type ModalContext = {
  event: MouseEvent;
  timeBlock: TimeBlock;
  timetableMasterName: string | null;
};

type InjectionValue = {
  targetElement: ComputedRef<HTMLElement | null>;
  timeBlock: ComputedRef<TimeBlock | undefined>;
  showsHeadcountUpdatePopover: ModalWithContext<ModalContext>['showsModal'];
  showHeadcountUpdatePopover: ModalWithContext<ModalContext>['showModal'];
  hideHeadcountUpdatePopover: ModalWithContext<ModalContext>['hideModal'];
  notifyHeadcountUpdate: (headcountTo: number, options?: { toResting?: boolean }) => void;
};

const { provide, inject } = createInjection<InjectionValue>('useHeadcountUpdatePopover');

export function useHeadcountUpdatePopoverProvider(): void {
  const {
    context,
    showsModal: showsHeadcountUpdatePopover,
    showModal: showHeadcountUpdatePopover,
    hideModal: hideHeadcountUpdatePopover,
  } = useModalWithContext<ModalContext>();

  const { notify } = useNotification(HeadcountUpdateNotification);

  const notifyHeadcountUpdate = (headcountTo: number, options?: { toResting?: boolean }) => {
    if (context.value === null) {
      return;
    }
    const startTime = timeStrToTimeInteger(context.value.timeBlock.displayTime);
    const endTime = getNextIntervalUnitMinutes(startTime);
    const notificationProps = {
      timetableMasterName: context.value.timetableMasterName,
      headcountFrom: context.value.timeBlock.headcount,
      headcountTo,
      startTime,
      endTime,
      toResting: options?.toResting ?? false,
    };
    notify(notificationProps);
  };

  provide({
    targetElement: computed(() =>
      context.value?.event.target instanceof HTMLElement ? context.value?.event.target : null,
    ),
    timeBlock: computed(() => context.value?.timeBlock),
    showsHeadcountUpdatePopover,
    showHeadcountUpdatePopover,
    hideHeadcountUpdatePopover,
    notifyHeadcountUpdate,
  });
}

export function useHeadcountUpdatePopover(): InjectionValue {
  return inject();
}
