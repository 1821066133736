import { computed, type ComputedRef } from '@vue/composition-api';
import { timeStrToTimeInteger } from 'src/util/datetime';
import { createInjection } from 'src/util/createInjection';
import { useModalWithContext, type ModalWithContext } from 'src/composables/useModalWithContext';
import type { TimetableMaster } from 'src/models/timetableMaster';
import type { ProgressSectionQuarterTime, QuarterSegment } from '../../types';
import ProductivityUpdateNotification from '../../components/UpdateNotification/ProductivityUpdateNotification.vue';
import { useNotification } from './composables/useNotification';

type ModalContext = {
  event: MouseEvent;
  timetableMaster: TimetableMaster | null;
  quarterSegment: QuarterSegment;
  progressSectionQuarterTime: ProgressSectionQuarterTime;
  initialValue: number;
};

type InjectionValue = {
  targetElement: ComputedRef<HTMLElement | null>;
  timetableMaster: ComputedRef<TimetableMaster | null>;
  quarterSegment: ComputedRef<QuarterSegment | null>;
  displayTime: ComputedRef<string>;
  progressSectionQuarterTime: ComputedRef<ProgressSectionQuarterTime>;
  getInitialValue: () => number;
  showsProductivityUpdatePopover: ModalWithContext<ModalContext>['showsModal'];
  showProductivityUpdatePopover: ModalWithContext<ModalContext>['showModal'];
  hideProductivityUpdatePopover: ModalWithContext<ModalContext>['hideModal'];
  notifyProductivityUpdate: (productivityTo: number) => void;
};

const { provide, inject } = createInjection<InjectionValue>('useProductivityUpdatePopover');

export function useProductivityUpdatePopoverProvider(): void {
  const {
    context,
    showsModal: showsProductivityUpdatePopover,
    showModal: showProductivityUpdatePopover,
    hideModal: hideProductivityUpdatePopover,
  } = useModalWithContext<ModalContext>();

  const { notify } = useNotification(ProductivityUpdateNotification);

  const notifyProductivityUpdate = (productivityTo: number) => {
    if (context.value === null) {
      return;
    }
    const startTime = timeStrToTimeInteger(context.value.quarterSegment.displayTime);
    const notificationProps = {
      timetableMasterName: context.value.timetableMaster?.name ?? '',
      productivityTo,
      startTime,
    };
    notify(notificationProps);
  };

  provide({
    targetElement: computed(() =>
      context.value?.event.target instanceof HTMLElement ? context.value?.event.target : null,
    ),
    timetableMaster: computed(() => context.value?.timetableMaster ?? null),
    quarterSegment: computed(() => context.value?.quarterSegment ?? null),
    displayTime: computed(() => context.value?.quarterSegment.displayTime ?? ''),
    progressSectionQuarterTime: computed(
      () => context.value?.progressSectionQuarterTime ?? { start: '', target: '', end: '' },
    ),
    getInitialValue: () => context.value?.initialValue ?? 0,
    showsProductivityUpdatePopover,
    showProductivityUpdatePopover,
    hideProductivityUpdatePopover,
    notifyProductivityUpdate,
  });
}

export function useProductivityUpdatePopover(): InjectionValue {
  return inject();
}
