import { type Component } from 'vue';
import { getCurrentInstance, type ExtractPropTypes } from '@vue/composition-api';

// Component<Data, Methods, Computed, Props> の第4型引数である Props 型を抽出するユーティリティ
type ExtractComponentProps<C> = C extends Component<any, any, any, infer P> ? P : never;

// 動的にコンポーネントを通知として表示するための仕組みを提供する。
// 複雑な通知表示に対応するため、メッセージではなくコンポーネントを要求するようにしている。
// コンポーネントクラスを引数として渡すことで、その型からProps型を抽出し、型安全な通知生成を可能にしている。
export function useNotification<C extends Component<any, any, any, any>>(component: C) {
  const root = getCurrentInstance()?.proxy!;
  type Props = ExtractPropTypes<ExtractComponentProps<C>>;

  const notify = (props: Props) => {
    root.$notify({
      component: {
        render: (h) => h(component, { props }),
      },
      verticalAlign: 'bottom',
      horizontalAlign: 'right',
      timeout: 10 * 1000,
    });
  };

  return { notify };
}
