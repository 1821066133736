export function toNumber(str: string | number | null): number {
  if (str === '' || str === null) {
    return 0;
  }
  const num = Number(str);
  return isNaN(num) ? 0 : num;
}

export function toNumberOrNull(str: string | number | null): number | null {
  if (str === '' || str === null) {
    return null;
  }
  const num = Number(str);
  return isNaN(num) ? null : num;
}

function roundToDecimal(num: number, decimal: number): number {
  return Math.round(num * Math.pow(10, decimal)) / Math.pow(10, decimal);
}

export function roundToInteger(num: number): number {
  return roundToDecimal(num, 0);
}

export function roundToOneDecimal(num: number): number {
  return roundToDecimal(num, 1);
}
