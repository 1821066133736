















import { defineComponent, type PropType } from '@vue/composition-api';

export default defineComponent({
  props: {
    label: {
      type: String,
      require: true,
    },
    type: {
      type: String as PropType<'checkbox'>,
      default: '',
    },
  },
});
