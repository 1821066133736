import axios, { AxiosResponse } from 'axios';
import { getApiUrl } from './apiUtils';
import { StringOrNumber } from 'src/models/common';
import { CreateParams, UpdateParams, AuthorizeApplicationParams } from 'src/models/api/oauthApplicationRequest';
import { OauthApplicationResponse, AuthorizeApplicationResponse } from 'src/models/api/oauthApplicationResponse';

export default {
  index(): Promise<OauthApplicationResponse[]> {
    return axios.get(getApiUrl(`/oauth/applications`)).then(({ data }) => data);
  },
  show(oauthApplicationId: StringOrNumber): Promise<OauthApplicationResponse> {
    return axios.get(getApiUrl(`/oauth/applications/${oauthApplicationId}`)).then(({ data }) => data);
  },
  create(data: CreateParams): Promise<OauthApplicationResponse> {
    return axios.post(getApiUrl('/oauth/applications'), data).then(({ data }) => data);
  },
  update(oauthApplicationId: StringOrNumber, data: UpdateParams): Promise<OauthApplicationResponse> {
    return axios.put(getApiUrl(`/oauth/applications/${oauthApplicationId}`), data).then(({ data }) => data);
  },
  delete(oauthApplicationId: StringOrNumber): Promise<OauthApplicationResponse> {
    return axios.delete(getApiUrl(`/oauth/applications/${oauthApplicationId}`)).then(({ data }) => data);
  },
  authorizeApplication(data: AuthorizeApplicationParams): Promise<AuthorizeApplicationResponse> {
    return axios.post(getApiUrl('/oauth/authorize'), data).then(({ data }) => data);
  },
};
