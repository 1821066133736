import { MacroOperationMasterResponse } from 'src/models/api/macroOperationMasterResponse';
import { MacroOperationSegmentMaster } from './macroOperationSegmentMaster';

export type MacroOperationMaster = {
  id: number;
  budget_group_id: number;
  name: string;
  is_enabled: boolean;
  disp_order: number;
  disp_color: string;
  is_working_hours_save: boolean;
  macro_operation_segment_masters: MacroOperationSegmentMaster[];
};

export function convertMacroOperationMasterResponse(response: MacroOperationMasterResponse): MacroOperationMaster {
  return {
    ...response,
  };
}
