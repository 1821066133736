
















import { computed, defineComponent, type PropType } from '@vue/composition-api';
import { FormItem } from 'src/components/UIComponents/Form';
import NumberInput from 'src/components/UIComponents/Inputs/NumberInput/NumberInput.vue';

export default defineComponent({
  components: {
    FormItem,
    NumberInput,
  },
  props: {
    value: {
      type: Number as PropType<number | null>,
      required: false,
      default: null,
    },
    rules: {
      type: [String, Object],
      required: false,
      default: undefined,
    },
  },
  setup(props, { emit }) {
    const modelValue = computed({
      get: () => props.value,
      set: (value: number | null) => {
        emit('input', value);
      },
    });

    return { modelValue };
  },
});
