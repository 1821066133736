



















import { defineComponent } from '@vue/composition-api';
import { roundToOneDecimal } from 'src/util/numbers';

export default defineComponent({
  props: {
    quantity: {
      type: Number,
      required: true,
    },
    productivity: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return {
      roundToOneDecimal,
    };
  },
});
