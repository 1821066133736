import { ATTENDANCE_TYPE, AttendanceType, SHIFT_PHASE, type ShiftPhase } from 'src/consts';
import { type PreShiftItem, type ShiftItem, type ShiftDisplayContent } from 'src/models/regularShift';
import { unpackTimeIntegerToString } from 'src/util/datetime';
import {
  type GenericShift,
  type NullShift,
  getGenericShift,
} from 'src/views/Dashboard/Workplace/RegularShift/libs/displayModeHelper';

type ShiftDisplayInfo = {
  displayContent: string;
  shiftType: number;
  totalWorkHours: string;
  shiftId: number | null;
};

const {
  TBD,
  NORMAL,
  HOLIDAY_WORK,
  SUBSTITUTE_ATTENDANCE,
  SUBSTITUTE_HOLIDAY,
  PAID_VACATION,
  SPECIAL_HOLIDAY,
  ABSENCE,
  TBC,
  NO_SHIFT,
} = ATTENDANCE_TYPE;

const ATTENDANCE_TYPES_AT_WORK: number[] = [NORMAL, HOLIDAY_WORK, SUBSTITUTE_ATTENDANCE];

const { SCHEDULED, ACTUAL } = SHIFT_PHASE;

const isAttendanceType = (value: number): value is AttendanceType => {
  return (Object.values(ATTENDANCE_TYPE) as number[]).includes(value);
};

export const getShiftDisplayContent = (
  shifts: ShiftItem[],
  preShifts: PreShiftItem[],
  displayMode: ShiftPhase,
): ShiftDisplayContent[] => {
  const displayContents: ShiftDisplayContent[] = [];
  shifts.forEach((shift, i) => {
    const preShift = preShifts[i];
    // TODO: Shift, PreShift内で使用されている, attendance_type系の型がnumberであるため、アサーションしている
    //       Shift, PreShiftのattendance_type系の型が修正された際に、アサーションを消す
    const actualAttendanceType = (shift.data?.actual_attendance_type ?? NO_SHIFT) as AttendanceType;
    const scheduledAttendanceType = (shift.data?.scheduled_attendance_type ?? NO_SHIFT) as AttendanceType;
    // TOOD: models/staff_pre_shift.rbのattendance_typeのバリデーションが追加されたら、actualAttendanceType, scheduledAttendanceTypeと記述を合わせる
    const provisionalAttendanceType =
      preShift.data === undefined || preShift.data === null || !isAttendanceType(preShift.data.attendance_type)
        ? NO_SHIFT
        : preShift.data.attendance_type;

    const shiftInfoMap = {
      date: shift.date,
      checked: false,
      isAttendanceStatusMismatched: false,
      attendanceType: provisionalAttendanceType,
    };
    const displayInfo = _shiftDisplayInfo(shift, preShift, displayMode);

    if (displayInfo.shiftType === SCHEDULED) {
      shiftInfoMap.isAttendanceStatusMismatched = !_checkAttendanceStatusMatched(
        scheduledAttendanceType,
        provisionalAttendanceType,
      );
      shiftInfoMap.attendanceType = scheduledAttendanceType;
    } else if (displayInfo.shiftType === ACTUAL) {
      shiftInfoMap.isAttendanceStatusMismatched = !_checkAttendanceStatusMatched(
        actualAttendanceType,
        scheduledAttendanceType,
      );
      shiftInfoMap.attendanceType = actualAttendanceType;
    }

    displayContents.push({ ...shiftInfoMap, ...displayInfo });
  });

  return displayContents;
};

const _checkAttendanceStatusMatched = (attendanceType1: number, attendanceType2: number): boolean => {
  const workAttendances: number[] = [NORMAL, HOLIDAY_WORK, SUBSTITUTE_ATTENDANCE];
  return workAttendances.includes(attendanceType1) === workAttendances.includes(attendanceType2);
};

const _shiftDisplayInfo = (shift: ShiftItem, preShift: PreShiftItem, displayMode: ShiftPhase): ShiftDisplayInfo => {
  const formattedShift = getGenericShift(shift, preShift, displayMode);
  return _getDiplayShift(formattedShift);
};

const _getDiplayShift = (shift: GenericShift | NullShift): ShiftDisplayInfo => {
  const totalWorkHours = shift.hoursTotal > 0 ? shift.hoursTotal.toFixed(1).toString() : '';
  const displayContent = _getShiftDisplayContent(shift.attendanceType, shift.startTime, shift.endTime);

  return {
    shiftId: shift.shiftId,
    shiftType: shift.shiftType,
    totalWorkHours: totalWorkHours,
    displayContent,
  };
};

const _getShiftDisplayContent = (
  attendanceType: number | null,
  startTime: number | null,
  endTime: number | null,
): string => {
  return (
    _getShiftDisplayContentFromAttendanceType(attendanceType) ||
    _getShiftDisplayContentFromStartEndTime(startTime, endTime)
  );
};

const _getShiftDisplayContentFromAttendanceType = (attendanceType: number | null): string => {
  if (attendanceType === null) {
    return '-';
  }
  if (!ATTENDANCE_TYPES_AT_WORK.includes(attendanceType)) {
    return _convertNonWorkingAttendanceTypeToDisplayString(attendanceType);
  }
  // 勤怠が出勤系の場合は時間を表示するので、ここではfalsyな値を返す
  return '';
};

const _getShiftDisplayContentFromStartEndTime = (startTime: number | null, endTime: number | null): string => {
  if (startTime === null || endTime === null) {
    return '';
  }
  const [startHour, startMin] = unpackTimeIntegerToString(startTime);
  const [endHour, endMin] = unpackTimeIntegerToString(endTime);
  return `${startHour}:${startMin} ~ ${endHour}:${endMin}`;
};

const _convertNonWorkingAttendanceTypeToDisplayString = (attendanceType: number): string => {
  switch (attendanceType) {
    case TBD:
      return '未';
    case SUBSTITUTE_HOLIDAY:
      return '振替休日';
    case PAID_VACATION:
      return '有給休暇';
    case SPECIAL_HOLIDAY:
      return '特別休暇';
    case ABSENCE:
      return '欠勤';
    case TBC:
      return '要確認';
    case NO_SHIFT:
      return '-';
    default:
      return '';
  }
};
