import { BudgetGroupPlanBoardMisc } from 'src/models/budgetGroupPlanBoardMisc';
import { MacroOperationSegment } from 'src/models/macroOperationSegment';
import { parseYmdDate } from 'src/util/datetime';
import { isExist } from 'src/util/isExist';
import { MacroOperationResponse } from './api/macroOperationResponse';
import { convertMacroOperationMasterResponse, MacroOperationMaster } from './macroOperationMaster';

export type MacroOperation = {
  id: number | null;
  macro_operation_master_id: number;
  dt: Date;
  scheduled_attendance_hour: number;
  macro_operation_master: MacroOperationMaster | null;
  actual_working_hours: number | null;
  overtime_work_hours: number | null;
};

export type DailyPlanBoard = {
  total_man_hours: number | null;
};

export type MacroOperationIndexResultItem = {
  // workplace_extensionはstoreから取る
  dt: Date;
  budget_group_plan_board_misc: BudgetGroupPlanBoardMisc | null;
  macro_operations: MacroOperation[];
  macro_operation_segments: MacroOperationSegment[];
  scheduled_hours_total: number;
};

export type MacroOperationIndexResult = MacroOperationIndexResultItem[];

export function convertMacroOperationResponse(response: MacroOperationResponse): MacroOperation {
  return {
    ...response,
    dt: parseYmdDate(response.dt),
    scheduled_attendance_hour: Number(response.scheduled_attendance_hour),
    actual_working_hours: isExist(response.actual_working_hours) ? Number(response.actual_working_hours) : null,
    macro_operation_master: isExist(response.macro_operation_master)
      ? convertMacroOperationMasterResponse(response.macro_operation_master)
      : null,
    overtime_work_hours: isExist(response.overtime_work_hours) ? Number(response.overtime_work_hours) : null,
  };
}
