import Vue from 'vue';
import { computed, reactive } from '@vue/composition-api';
import { vvHasError } from 'src/util/vee_validate';

export interface ValidationsState {
  validations: any;
  hasError: boolean;
}

export function getValidationsState(root: Vue, statuses = []): ValidationsState {
  const validationsState: ValidationsState = reactive({
    validations: getValidationMap(),
    hasError: computed(() => {
      return vvHasError(root);
    }),
  });
  function getValidationMap(): any {
    return {
      name: { required: true, max: 255 },
      redirect_uri: {
        required: true,
        url: {
          protocols: ['https', 'http'],
          require_protocol: true,
          require_tld: false,
        },
      },
    };
  }
  return validationsState;
}
