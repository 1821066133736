
import Vue from 'vue';
import { defineComponent, onMounted, reactive, SetupContext } from '@vue/composition-api';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import { Dictionary } from 'vue-router/types/router';

interface State {
  userId: number;
  accessGrantToken: string | null;
}

function setupState(root: Vue): State {
  const state: State = reactive({
    userId: wrappedMapGetters(root.$store, 'user', ['id']).id,
    accessGrantToken: null,
  });
  return state;
}

export default defineComponent({
  setup(props, context: SetupContext) {
    const root = context.root as Vue;
    setPageName(root, 'API連携用アプリケーション (Beta版)');
    const state = setupState(root);

    function backToOauthList(): void {
      root.$router.push({
        name: 'GeneralSettingsOauth',
      });
    }

    onMounted(async () => {
      const routeQuery: Dictionary<any> = root.$route.query;
      if (root.$route.query.code) {
        state.accessGrantToken = routeQuery.code;
      }
    });

    return {
      state,
      backToOauthList,
    };
  },
});
