











































import { defineComponent } from '@vue/composition-api';
import { PanelList, PanelListItem } from '../PanelList';
import { ActivityStatusPanel } from 'src/views/Dashboard/Workplace/components/ActivityStatusPanel';
import { AttendanceStatusPanel } from 'src/views/Dashboard/Workplace/components/AttendanceStatusPanel';
import { OperationPanel } from '../OperationPanel';
import { MemoPanel } from '.././MemoPanel';
import { TimetableGroupPanel } from '../TimetableGroupPanel';
import { useProgressHeaders } from '../../composables/useProgressHeaders';
import { useCurrentStaffWork } from '../../composables/useCurrentStaffWork';

export default defineComponent({
  components: {
    PanelList,
    PanelListItem,
    ActivityStatusPanel,
    AttendanceStatusPanel,
    TimetableGroupPanel,
    OperationPanel,
    MemoPanel,
  },
  setup() {
    const { progressHeaders, timetableGroupMasterWithProgressHeaders, productivityGraph, filterProgressHeaders } =
      useProgressHeaders();

    const { showStatusPanel, currentStaffWork } = useCurrentStaffWork();

    return {
      showStatusPanel,
      progressHeaders,
      currentStaffWork,
      timetableGroupMasterWithProgressHeaders,
      productivityGraph,
      filterProgressHeaders,
    };
  },
});
