

















import { defineComponent, computed, type PropType } from '@vue/composition-api';
import { type TimetableActivityMaster } from 'src/models/timetableActivityMaster';

export default defineComponent({
  props: {
    value: {
      type: Array as PropType<TimetableActivityMaster['id'][]>,
      required: true,
    },
    timetableActivityMasters: {
      type: Array as PropType<TimetableActivityMaster[]>,
      required: true,
    },
  },
  setup(props, context) {
    const options = computed(() =>
      props.timetableActivityMasters.map((timetableActivityMaster) => ({
        label: `${timetableActivityMaster.budget_unit.name} ${timetableActivityMaster.name}`,
        value: timetableActivityMaster.id,
      })),
    );
    const optionIndexes = computed(() => new Map(options.value.map((v, i) => [v.value, i])));
    const values = computed(() =>
      // 選択肢と選択した値の表示順を合わせる
      [...props.value].sort((a, b) => (optionIndexes.value.get(a) ?? 0) - (optionIndexes.value.get(b) ?? 0)),
    );
    const handleInput = (values: TimetableActivityMaster['id'][]) => {
      context.emit('input', values);
    };

    return {
      values,
      options,
      handleInput,
    };
  },
});
