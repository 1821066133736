import Vue from 'vue';
import { computed, reactive } from '@vue/composition-api';
import { OauthApplicationResponse } from 'src/models/api/oauthApplicationResponse';
import { deriveNonNullSubset, NonNullablePropsRequired } from 'src/util/type_util';

export type SaveCandidate = {
  id: number | null;
  name: string | null;
  redirect_uri: string | null;
  scopes: string | null;
};

export type CreateCandidate = NonNullablePropsRequired<Omit<SaveCandidate, 'id'>>;

export type UpdateCandidate = NonNullablePropsRequired<SaveCandidate>;

export interface State {
  list: OauthApplicationResponse[];
  hasList: boolean;
  showSaveModal: boolean;
  showDeleteModal: boolean;
  saveType: 'create' | 'update';
  saveCandidate: SaveCandidate;
  createCandidate: CreateCandidate | null;
  updateCandidate: UpdateCandidate | null;
  deleteCandidate: OauthApplicationResponse | null;
}

export function initialSaveCandidateState(): SaveCandidate {
  return {
    id: null,
    name: null,
    redirect_uri: null,
    scopes: null,
  };
}

const createCandidateTemplate: CreateCandidate = {
  name: '',
  redirect_uri: '',
  scopes: '',
};

const updateCandidateTemplate: UpdateCandidate = {
  id: 0, // number
  name: '',
  redirect_uri: '',
  scopes: '',
};

export function getState(root: Vue, statuses = []): State {
  const state: State = reactive({
    list: [],
    hasList: computed(() => {
      return Object.keys(state.list).length > 0;
    }),
    showSaveModal: false,
    showDeleteModal: false,
    saveType: 'update',
    saveCandidate: initialSaveCandidateState(),
    createCandidate: computed<CreateCandidate | null>(() => {
      return deriveNonNullSubset(state.saveCandidate, createCandidateTemplate);
    }),
    updateCandidate: computed<UpdateCandidate | null>(() => {
      return deriveNonNullSubset(state.saveCandidate, updateCandidateTemplate);
    }),
    deleteCandidate: null,
  });

  return state;
}
