import { type Validator } from 'src/composables/useCustomValidator';
import { isValid15StepMinutes } from 'src/values/TimeInteger/isValid15StepMinutes';
import { containsTimeRange, isStrictTimeRange, isTimeRange, isValidTimeRange } from 'src/values/TimeRange';

export const useTimeRangeValidator = () => {
  const strictValidator: Validator = {
    name: 'strictTimeRange',
    validate(timeRange: unknown) {
      return isStrictTimeRange(timeRange) && isValidTimeRange(timeRange);
    },
  };

  const in15StepMinutesValidator: Validator = {
    name: 'in15StepMinutes',
    validate(timeRange: unknown) {
      return (
        isStrictTimeRange(timeRange) &&
        isValidTimeRange(timeRange) &&
        isValid15StepMinutes(timeRange.startTime) &&
        isValid15StepMinutes(timeRange.endTime)
      );
    },
  };

  const afterOrEqualValidator: Validator = {
    name: 'afterOrEqual',
    validate(timeRange: unknown, targets) {
      const targetTimeRange = Array.isArray(targets) ? targets[0] : null;
      if (
        !isTimeRange(timeRange) ||
        !isTimeRange(targetTimeRange) ||
        timeRange.startTime === null ||
        targetTimeRange.endTime === null
      ) {
        return false;
      }
      return timeRange.startTime >= targetTimeRange.endTime;
    },
    options: { hasTarget: true },
  };

  const withinValidator: Validator = {
    name: 'timeRangeWithin',
    validate(timeRange: unknown, targets) {
      const targetTimeRange = Array.isArray(targets) ? targets[0] : null;
      if (!isStrictTimeRange(timeRange) || !isStrictTimeRange(targetTimeRange)) {
        return false;
      }
      return containsTimeRange(targetTimeRange, timeRange);
    },
    options: { hasTarget: true },
  };

  return {
    strictValidator,
    in15StepMinutesValidator,
    afterOrEqualValidator,
    withinValidator,
  };
};
