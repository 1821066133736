import { type TimetableActivityMaster } from 'src/models/timetableActivityMaster';

export function getActivityNamesString(
  timetableActivityMasters: TimetableActivityMaster[],
  activityMasterIds: number[],
): string {
  return timetableActivityMasters
    .filter((v) => activityMasterIds.includes(v.id))
    .map((v) => `${v.budget_unit.name} ${v.name}`)
    .join(', ');
}
