






























import { defineComponent, onMounted, onUnmounted, ref, watch, type PropType } from '@vue/composition-api';
import { isExist } from 'src/util/isExist';

const Y_MARGIN = 16;
const LEFT_MARGIN = 150;

export default defineComponent({
  props: {
    showsPopover: {
      type: Boolean,
      required: true,
    },
    hidePopover: {
      type: Function as PropType<() => void>,
      required: true,
    },
    targetElement: {
      type: HTMLElement as PropType<HTMLElement | null>,
      required: false,
      default: null,
    },
  },
  setup(props, _) {
    const isTop = ref(false);
    const top = ref(0);
    const left = ref(0);
    const rootElement = ref<HTMLDivElement>();
    const popoverElement = ref<HTMLDivElement>();
    const lastTimerKey = ref(0);

    // ポップオーバーの表示位置の調整を行う。
    // Popoverコンポーネントがアンマウントされている状態から表示しようとした場合のみtargetElementの変更を検知できないため、popoverElementも監視対象にしている。
    // スクロールに応じてポップオーバーの位置を調整するため、100ms単位でresetPopoverPositionを実行する。
    watch(
      () => [props.targetElement, popoverElement.value],
      () => {
        window.clearInterval(lastTimerKey.value);
        if (!isExist(props.targetElement) || !(popoverElement.value instanceof HTMLDivElement)) {
          return;
        }
        if (!props.showsPopover) {
          return;
        }
        resetPopoverPosition();
        lastTimerKey.value = window.setInterval(resetPopoverPosition, 100);
      },
    );

    // Popoverの描画エリアが画面からはみ出さないように位置を調節
    // 画面の下にPopoverがはみ出す場合は、上に表示する(isTop = true)
    const resetPopoverPosition = () => {
      if (!(props.targetElement instanceof HTMLElement) || !(popoverElement.value instanceof HTMLDivElement)) {
        return;
      }
      const targetRect = props.targetElement.getBoundingClientRect();
      const popoverRect = popoverElement.value.getBoundingClientRect();
      const isOver = targetRect.top + targetRect.height + popoverRect.height > window.innerHeight;
      top.value = isOver
        ? targetRect.top - popoverRect.height - Y_MARGIN
        : targetRect.top + targetRect.height + Y_MARGIN;
      left.value = targetRect.left - LEFT_MARGIN + targetRect.width / 2;
      isTop.value = isOver;
    };

    const onDocumentClick = (event: MouseEvent) => {
      const target = event.target;
      if (!props.showsPopover) {
        return;
      }
      if (!(target instanceof HTMLElement)) {
        return;
      }
      // クリックイベントが発生した直接のHTML要素がPopover内の要素である場合は、Popoverを閉じない
      if (isExist(target.closest('.Popover-container'))) {
        return;
      }
      // ポップオーバーを開くためにクリックした要素をクリックしてもポップオーバーを閉じない
      if (isExist(props.targetElement) && props.targetElement.contains(target)) {
        return;
      }
      props.hidePopover();
    };

    onMounted(() => {
      document.addEventListener('click', onDocumentClick);
    });

    onUnmounted(() => {
      document.removeEventListener('click', onDocumentClick);
    });

    return {
      top,
      left,
      rootElement,
      popoverElement,
      isTop,
      isExist,
    };
  },
});
