import { type ShiftPhase } from 'src/consts';
import {
  type RegularShiftMacroOperationSegment,
  type RegularShiftMacroOperationSegmentMaster,
  type StaffWithShifts,
} from 'src/models/regularShift';
import { getGenericShift } from 'src/views/Dashboard/Workplace/RegularShift/libs/displayModeHelper';

export const getTotalBaseValue = (
  date: string,
  macroOperationSegmentMasters: RegularShiftMacroOperationSegmentMaster[],
): number => {
  const dateMatchedMacroOperationSegments = _getDateMatchedMacroOperationSegment(date, macroOperationSegmentMasters);
  const totalBaseValue = dateMatchedMacroOperationSegments.reduce((accu, data) => {
    return accu + data.required_working_hours;
  }, 0);

  return Math.round(totalBaseValue);
};

const _getDateMatchedMacroOperationSegment = (
  date: string,
  macroOperationSegmentMasters: RegularShiftMacroOperationSegmentMaster[],
): RegularShiftMacroOperationSegment[] => {
  const macroOperationSegments = macroOperationSegmentMasters.flatMap(
    (segmentMaster) => segmentMaster.macro_operation_segments,
  );
  return macroOperationSegments.filter((segment) => segment.dt === date);
};

// 人時を取得 (人数でも使用<人時が0以外だったら人数が1>)
export const getManHours = (staff: StaffWithShifts, dateIndex: number, displayMode: ShiftPhase): number => {
  const preShift = staff.orig_pre_shifts[dateIndex];
  const shift = staff.orig_shifts[dateIndex];

  return getGenericShift(shift, preShift, displayMode).hoursTotal;
};
