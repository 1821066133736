





















































































































import Vue from 'vue';
import { defineComponent, computed, type PropType } from '@vue/composition-api';
import { type TimetableMaster } from 'src/models/timetableMaster';
import { getActivityMasterIds } from 'src/util/timetableMasterExternalSourceHelpers';
import { useTimetableActivityMasters } from '../../composables/useTimetableActivityMasters';
import { formatTime, formatNumber, formatFixedDecimal } from '../../utils/filters';
import { getAppropriationDate } from '../../utils/getAppropriationDate';
import TimetableMasterListRow from './TimetableMasterListRow.vue';
import TimetableMasterListCell from './TimetableMasterListCell.vue';
import TimetableMasterListControls from './TimetableMasterListControls.vue';
import { getActivityNamesString } from './getActivityNamesString';
import { getDisplayTime } from './getDisplayTime';
import { getTimetableMasterLogimeterActivitiesString } from './getTimetableMasterLogimeterActivitiesString';

export default defineComponent({
  filters: {
    formatTime,
    formatNumber,
    formatFixedDecimal,
  },
  components: {
    TimetableMasterListRow,
    TimetableMasterListCell,
    TimetableMasterListControls,
  },
  props: {
    timetableMaster: {
      type: Object as PropType<TimetableMaster>,
      required: true,
    },
    onEdit: {
      type: Function as PropType<(timetableMaster: TimetableMaster) => unknown>,
      required: true,
    },
    onDelete: {
      type: Function as PropType<(timetableMaster: TimetableMaster) => unknown>,
      required: true,
    },
  },
  setup(props, context) {
    const root = context.root as Vue;
    const { timetableActivityMasters } = useTimetableActivityMasters();
    const break1Time = computed(() =>
      getDisplayTime(props.timetableMaster.break1_start_time, props.timetableMaster.break1_end_time),
    );
    const break2Time = computed(() =>
      getDisplayTime(props.timetableMaster.break2_start_time, props.timetableMaster.break2_end_time),
    );
    const timetableLabelName = computed(() => props.timetableMaster.timetable_label?.name ?? '');
    const activityNamesStringForQuantity = computed(() =>
      getActivityNamesString(
        timetableActivityMasters.value,
        getActivityMasterIds(props.timetableMaster.timetable_master_external_sources, 'use_for_quantity'),
      ),
    );
    const timetableMasterLogimeterActivitiesString = computed(() =>
      getTimetableMasterLogimeterActivitiesString(props.timetableMaster, timetableActivityMasters.value),
    );
    const appropriationDate = computed(() => getAppropriationDate(props.timetableMaster));
    const handleClick = () => {
      root.$router.push({
        name: 'WorkplaceOperationOverview',
        params: {
          date: 'today',
        },
        query: {
          timetable_master_id: String(props.timetableMaster.id),
          display: '1',
        },
      });
    };

    return {
      break1Time,
      break2Time,
      timetableLabelName,
      activityNamesStringForQuantity,
      timetableMasterLogimeterActivitiesString,
      appropriationDate,
      handleClick,
    };
  },
});
