import { TimetableMasterExternalSourceResponse } from 'src/models/api/timetableMasterExternalSourceResponse';

export type TimetableMasterExternalSource = {
  id: number;
  timetable_master_id: number;
  source_system: 'logimeter';
  source_type: 'activity_master';
  source_id: number;
  usage_type: 'use_for_quantity' | 'use_for_man_hours';
};

export function convertTimetableMasterExternalSourceResponse(
  response: TimetableMasterExternalSourceResponse,
): TimetableMasterExternalSource {
  return { ...response };
}
