



































import { defineComponent, watch, ref } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';
import { isExist } from 'src/util/isExist';
import { PrimaryButton } from 'src/components/UIComponents/Buttons/PrimaryButton';
import Popover from './components/Popover.vue';
import NumberInput from './components/NumberInput.vue';
import { useDailySimulationValidationRules } from '../../composables/useDailySimulationValidationRules';
import { useProductivityUpdatePopover } from '../../composables/UpdatePopover/useProductivityUpdatePopover';

export default defineComponent({
  components: {
    NumberInput,
    PrimaryButton,
    ValidationObserver,
    Popover,
  },
  setup(_, context) {
    const {
      targetElement,
      displayTime,
      getInitialValue,
      hideProductivityUpdatePopover,
      showsProductivityUpdatePopover,
      notifyProductivityUpdate,
    } = useProductivityUpdatePopover();

    const productivityToUpdate = ref<number>(0);

    const { productivityValidationRules } = useDailySimulationValidationRules();

    const updateProductivity = () => {
      notifyProductivityUpdate(productivityToUpdate.value);
      context.emit('update', productivityToUpdate.value);
      hideProductivityUpdatePopover();
    };

    watch(targetElement, () => {
      productivityToUpdate.value = getInitialValue();
    });

    return {
      targetElement,
      displayTime,
      productivityToUpdate,
      showsProductivityUpdatePopover,
      productivityValidationRules,
      hideProductivityUpdatePopover,
      updateProductivity,
      isExist,
    };
  },
});
