var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModalForm',{attrs:{"on-submit":_vm.handleSubmit,"show":_vm.show,"title":_vm.title},on:{"close":function($event){return _vm.$emit('close')}}},[_c('FormGroup',{attrs:{"has-spacing":"","label":"パターン名"}},[_c('FormItem',{attrs:{"rules":"required|max:10|excluded:-"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hasError = ref.hasError;
return [_c('TextInput',{attrs:{"has-error":hasError},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}])})],1),_c('FormGroup',{attrs:{"label":"勤務"}},[_c('FormItem',{attrs:{"rules":_vm.shiftTimeRules,"vid":"shiftTimeRange"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hasError = ref.hasError;
return [_c('TimeRangeInput',{attrs:{"has-error":hasError},model:{value:(_vm.shiftTimeRange),callback:function ($$v) {_vm.shiftTimeRange=$$v},expression:"shiftTimeRange"}})]}}])})],1),_c('FormGroup',{attrs:{"has-spacing":"","label":"休憩"}},[_c('FormItem',{attrs:{"rules":_vm.break1TimeRules,"vid":"break1TimeRange"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hasError = ref.hasError;
return [_c('TimeRangeInput',{attrs:{"has-error":hasError},model:{value:(_vm.break1TimeRange),callback:function ($$v) {_vm.break1TimeRange=$$v},expression:"break1TimeRange"}})]}}])}),_c('FormItem',{attrs:{"rules":_vm.break2TimeRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hasError = ref.hasError;
return [_c('TimeRangeInput',{attrs:{"has-error":hasError},model:{value:(_vm.break2TimeRange),callback:function ($$v) {_vm.break2TimeRange=$$v},expression:"break2TimeRange"}})]}}])}),_c('TotalBreakTime',{attrs:{"time-ranges":[_vm.break1TimeRange, _vm.break2TimeRange]}})],1),_c('FormGroup',{attrs:{"label":"有効","type":"switch"}},[_c('FormItem',[_c('ToggleSwitch',{model:{value:(_vm.isEnabled),callback:function ($$v) {_vm.isEnabled=$$v},expression:"isEnabled"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }