
import Vue from 'vue';
import * as C from 'src/consts';
import { defineComponent, SetupContext, onMounted } from '@vue/composition-api';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { notifySuccess1, notifyError1 } from 'src/hooks/notificationHook';
import { OauthApplicationResponse } from 'src/models/api/oauthApplicationResponse';
import oauthApplicationApi from 'src/apis/oauth_application';
import { vvGetError, vvReset, vvValidate } from 'src/util/vee_validate';
import { State, getState, initialSaveCandidateState } from 'src/views/Dashboard/Settings/Oauth/states/state';
import { SettingsState, getSettingsState } from 'src/views/Dashboard/Settings/Oauth/states/settingsState';
import { ValidationsState, getValidationsState } from 'src/views/Dashboard/Settings/Oauth/states/validationsState';
import { routerShowNextPage } from 'src/hooks/routeHook';

function setupState(context: SetupContext): State {
  const root = context.root as Vue;
  return getState(root);
}

function setupSettingsState(context: SetupContext): SettingsState {
  const root = context.root as Vue;
  return getSettingsState(root);
}

function setupValidationsState(context: SetupContext): ValidationsState {
  const root = context.root as Vue;
  return getValidationsState(root);
}

export default defineComponent({
  setup(props, context: SetupContext) {
    const root = context.root as Vue;
    setPageName(root, 'API連携用アプリケーション (Beta版)');

    const state = setupState(context);
    const settingsState = setupSettingsState(context);
    const validationsState = setupValidationsState(context);

    function getError(fieldName: string): string | null {
      return vvGetError(root, fieldName);
    }

    function clearErrors(): void {
      vvReset(root);
    }

    async function getList(): Promise<void> {
      state.list = await oauthApplicationApi.index();
    }

    function openCreateModal(): void {
      state.saveType = 'create';
      // 現状scopesは'all'決めうちにしている。
      // 将来これを分ける場合は以下の行をなくして、
      // 画面側からの入力に応じて適切なスコープを入れるようにすること。
      // なお、Rails側でも受け入れる値のチェックが入るので注意
      // (config/initializers/doorkeeper.rbのdefault_scopes、
      //  enforce_configured_scopesあたりを参照)
      state.saveCandidate.scopes = 'all';
      state.showSaveModal = true;
    }

    function openUpdateModal(item: OauthApplicationResponse): void {
      state.saveCandidate = {
        id: item.id,
        name: item.name,
        redirect_uri: item.redirect_uri,
        // scopesは現状決めうち。画面からは更新できないので注意
        scopes: item.scopes,
      };
      state.saveType = 'update';
      state.showSaveModal = true;
    }

    function closeSaveModal(): void {
      state.saveCandidate = initialSaveCandidateState();
      clearErrors();
      state.showSaveModal = false;
    }

    async function createItem(): Promise<void> {
      const isValid = await vvValidate(root);
      if (!isValid) {
        return;
      }
      if (state.createCandidate === null) {
        return;
      }
      try {
        await oauthApplicationApi.create(state.createCandidate);
        await getList();
        closeSaveModal();
        notifySuccess1(root, 'API連携用アプリケーションを作成しました');
      } catch (error: any) {
        const errorId = 'ERR00001';
        const message =
          'API連携用アプリケーションの作成に失敗しました。' +
          `管理者に連絡してください。` +
          `(ERR: ${settingsState.pageName} ${errorId}, user_id:${settingsState.userId})`;
        notifyError1(root, message, error);
      }
    }

    async function updateItem(): Promise<void> {
      const isValid = await vvValidate(root);
      if (!isValid) {
        return;
      }
      if (state.updateCandidate === null) {
        return;
      }
      try {
        // update
        await oauthApplicationApi.update(state.updateCandidate.id, state.updateCandidate);
        await getList();
        closeSaveModal();
        notifySuccess1(root, 'API連携用アプリケーションを編集しました');
      } catch (error: any) {
        const errorId = 'ERR00001';
        const message =
          'API連携用アプリケーションの編集に失敗しました。' +
          `管理者に連絡してください。` +
          `(ERR: ${settingsState.pageName} ${errorId}, user_id:${settingsState.userId})`;
        notifyError1(root, message, error);
      }
    }

    function openDeleteModal(item: OauthApplicationResponse): void {
      state.deleteCandidate = item;
      state.showDeleteModal = true;
    }

    function closeDeleteModal(): void {
      state.deleteCandidate = null;
      state.showDeleteModal = false;
    }

    async function deleteItem(): Promise<void> {
      try {
        await oauthApplicationApi.delete(state.deleteCandidate!.id);
        await getList();
        closeDeleteModal();
        notifySuccess1(root, 'API連携用アプリケーションを削除しました');
      } catch (error: any) {
        const errorId = 'ERR00003';
        const message =
          'API連携用アプリケーションの削除に失敗しました。' +
          '管理者に連絡してください。' +
          `(ERR: ${settingsState.pageName} ${errorId}, user_id:${settingsState.userId})`;
        notifyError1(root, message, error);
      }
    }

    async function openOauthDetail(oauthApplicationId: number, evt: MouseEvent | KeyboardEvent): Promise<void> {
      await routerShowNextPage(context, evt, {
        name: 'GeneralSettingsOauthDetail',
        params: {
          oauthApplicationId: oauthApplicationId.toString(),
        },
      });
    }

    function getPredefinedRedirectUri(): string {
      return `${location.protocol}//${location.host}${C.OAUTH_PREDEFINED_CALLBACK_URL_PATH}`;
    }

    function inputPredefinedRedirectUri(): void {
      state.saveCandidate.redirect_uri = getPredefinedRedirectUri();
    }

    onMounted(async () => {
      await getList();
    });

    return {
      state,
      settingsState,
      validationsState,
      getError,
      openCreateModal,
      openUpdateModal,
      closeSaveModal,
      createItem,
      updateItem,
      openDeleteModal,
      closeDeleteModal,
      deleteItem,
      openOauthDetail,
      getPredefinedRedirectUri,
      inputPredefinedRedirectUri,
    };
  },
});
