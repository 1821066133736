





































































import { defineComponent, ref, type PropType } from '@vue/composition-api';
import { ModalForm, FormGroup, FormItem } from 'src/components/UIComponents/Form';
import { TextInput } from 'src/components/UIComponents/Inputs/TextInput';
import { TimeRangeInput } from 'src/components/UIComponents/Inputs/TimeRangeInput';
import { ToggleSwitch } from 'src/components/UIComponents/Inputs/ToggleSwitch';
import { useCustomValidator } from 'src/composables/useCustomValidator';
import { type ShiftPattern } from 'src/models/workplaceMasters/shiftPattern';
import { type TimeRange, createTimeRange } from 'src/values/TimeRange';
import TotalBreakTime from './TotalBreakTime.vue';
import { type ShiftPatternInput } from './types';
import { useTimeRangeValidator } from 'src/composables/useTimeRangeValidator';

export default defineComponent({
  components: {
    ModalForm,
    FormGroup,
    FormItem,
    TextInput,
    TimeRangeInput,
    ToggleSwitch,
    TotalBreakTime,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
    shiftPattern: {
      type: Object as PropType<ShiftPattern | null>,
      default: null,
    },
    onSubmit: {
      type: Function as PropType<(shiftPatternInput: ShiftPatternInput) => Promise<unknown>>,
      required: true,
    },
  },
  setup(props) {
    const { in15StepMinutesValidator, afterOrEqualValidator, withinValidator } = useTimeRangeValidator();
    const in15StepMinutesRule = useCustomValidator(in15StepMinutesValidator);
    const afterOrEqualRule = useCustomValidator(afterOrEqualValidator);
    const withinRule = useCustomValidator(withinValidator);
    const shiftTimeRules = `required|${in15StepMinutesRule}`;
    const break1TimeRules = `${in15StepMinutesRule}|${withinRule}:shiftTimeRange`;
    const break2TimeRules = `${in15StepMinutesRule}|${withinRule}:shiftTimeRange|${afterOrEqualRule}:break1TimeRange`;
    const name = ref(props.shiftPattern?.name ?? '');
    const shiftTimeRange = ref<TimeRange | null>(
      createTimeRange(props.shiftPattern?.start_time ?? null, props.shiftPattern?.end_time ?? null),
    );
    const break1TimeRange = ref<TimeRange | null>(
      createTimeRange(props.shiftPattern?.break1_start_time ?? null, props.shiftPattern?.break1_end_time ?? null),
    );
    const break2TimeRange = ref<TimeRange | null>(
      createTimeRange(props.shiftPattern?.break2_start_time ?? null, props.shiftPattern?.break2_end_time ?? null),
    );
    const isEnabled = ref(props.shiftPattern?.is_enabled ?? true);
    const handleSubmit = async () => {
      await props.onSubmit({
        name: name.value,
        start_time: shiftTimeRange.value?.startTime!,
        end_time: shiftTimeRange.value?.endTime!,
        break1_start_time: break1TimeRange.value?.startTime ?? null,
        break1_end_time: break1TimeRange.value?.endTime ?? null,
        break2_start_time: break2TimeRange.value?.startTime ?? null,
        break2_end_time: break2TimeRange.value?.endTime ?? null,
        is_enabled: isEnabled.value,
      });
    };

    return {
      shiftTimeRules,
      break1TimeRules,
      break2TimeRules,
      name,
      shiftTimeRange,
      break1TimeRange,
      break2TimeRange,
      isEnabled,
      handleSubmit,
    };
  },
});
