
















import { defineComponent, computed, type PropType } from '@vue/composition-api';
import { useTruncatedText } from 'src/composables/useTruncatedText';
import { TimeInteger } from 'src/models/common';
import { formatTimeInteger } from 'src/util/datetime';

export default defineComponent({
  props: {
    timetableMasterName: {
      type: String as PropType<string | null>,
      default: null,
    },
    startTime: {
      type: Number as PropType<TimeInteger | null>,
      required: false,
      default: null,
    },
    endTime: {
      type: Number as PropType<TimeInteger | null>,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const timetableMasterName = computed(() => props.timetableMasterName ?? '');
    const { elementRef } = useTruncatedText({ text: timetableMasterName });
    const formatTime = (timeInteger: TimeInteger) => formatTimeInteger(timeInteger, 'HH : MM');
    const displayTimeRange = computed(() => {
      return [props.startTime, props.endTime]
        .map((item) => (typeof item === 'number' ? formatTime(item) : item))
        .join(' - ');
    });
    return {
      elementRef,
      displayTimeRange,
    };
  },
});
