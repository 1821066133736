import Vue from 'vue';
import { reactive } from '@vue/composition-api';
import { wrappedMapGetters } from 'src/hooks/storeHook';

export interface SettingsState {
  pageName: string;
  userId: number;
}

export function getSettingsState(root: Vue): SettingsState {
  const settingsState: SettingsState = reactive({
    ...wrappedMapGetters(root.$store, 'displayPageName', ['pageName']),
    userId: wrappedMapGetters(root.$store, 'user', ['id']).id,
  });
  return settingsState;
}
