export const DEFAULT_DAY_START_HOUR = 5;

export const USER_WORKPLACE_ROLE_W_OWNER = 'w_owner';
export const USER_WORKPLACE_ROLE_W_LEADER = 'w_leader';
export const USER_WORKPLACE_ROLE_W_MEMBER = 'w_member';

export const USER_WORKPLACE_STATUS_SHARED = '01';
export const USER_WORKPLACE_STATUS_PENDING = '11';
export const USER_WORKPLACE_STATUS_DECLINED = '12';

export const ROUTER_DT_PARAM_TODAY = 'today';

export const WORK_START_TIME_INPUT_TYPE_NOT_ROUNDED = '1';
export const WORK_START_TIME_INPUT_TYPE_ROUNDED_UP = '2';
export const WORK_START_TIME_INPUT_TYPE_ROUNDED_DOWN = '3';
export const WORK_END_TIME_INPUT_TYPE_NOT_ROUNDED = '1';
export const WORK_END_TIME_INPUT_TYPE_ROUNDED_UP = '2';
export const WORK_END_TIME_INPUT_TYPE_ROUNDED_DOWN = '3';
export const ACTIVITY_TIME_INPUT_TYPE_NOT_ROUNDED = '1';
export const ACTIVITY_TIME_INPUT_TYPE_ROUNDED_UP = '2';
export const ACTIVITY_TIME_INPUT_TYPE_ROUNDED_DOWN = '3';

// 検索日付指定
// 31日間選択可能
export const SEARCH_DATE_RANGE_MAX = 31;

// データ画面表示件数
export const DATA_DISPLAY_ITEM_COUNT = 5000;
// データ画面一括変更可能件数
export const DATA_MAX_BULK_UPDATE_ITEM_COUNT = 50;
// CSV出力可能件数
export const CSV_DOWNLOAD_ITEM_COUNT = 100000;

// TimeInteger関連
export const TIME_INTEGER_MAX_VALUE = 475959;
export const TIME_INTEGER_MAX_HOUR = 47;
export const TIME_INTEGER_MAX_MINUTE = 59;

// 時間帯 (day_block1 か day_block2)
export type DayBlockNumber = 1 | 2;

// シフト関連
// 勤務帯
export const SHIFT_TIME_FRAMES = [
  { value: 1, name: '時間帯1' },
  { value: 2, name: '時間帯2' },
  { value: 3, name: '時間帯3' },
];
// シフトタイプ
export const SHIFT_TYPES = [
  { value: 1, name: '出勤' },
  { value: 2, name: '公休' },
  { value: 3, name: '有給' },
  { value: 4, name: 'シフトなし' },
];

// シフトのチェック上限
export const SHIFT_CHECK_LIMIT = 300;

// 勤怠タイプ
// 0:未定,1:通常出勤,2:休日出勤,3:振替出勤,4:振替休日,5:有給休暇,6:特別休暇,7:欠勤,8:要確認,9:シフトなし
export const ATTENDANCE_TYPE = {
  TBD: 0,
  NORMAL: 1,
  HOLIDAY_WORK: 2,
  SUBSTITUTE_ATTENDANCE: 3,
  SUBSTITUTE_HOLIDAY: 4,
  PAID_VACATION: 5,
  SPECIAL_HOLIDAY: 6,
  ABSENCE: 7,
  TBC: 8,
  NO_SHIFT: 9,
} as const;
export type AttendanceType = (typeof ATTENDANCE_TYPE)[keyof typeof ATTENDANCE_TYPE];

export const ATTENDANCE_TYPES_AT_WORK = [
  ATTENDANCE_TYPE.NORMAL,
  ATTENDANCE_TYPE.HOLIDAY_WORK,
  ATTENDANCE_TYPE.SUBSTITUTE_ATTENDANCE,
];

// 勤怠補足
export const ATTENDANCE_SUPPLEMENT = {
  PRE_CONTACTED: 1,
  TODAY_CONTACTED: 2,
  WITHOUT_NOTICE: 3,
} as const;
export type AttendanceSupplement = (typeof ATTENDANCE_SUPPLEMENT)[keyof typeof ATTENDANCE_SUPPLEMENT];

export const SHIFT_TOTAL_MODE = {
  MAN_HOUR: 1,
  MAN_HOUR_DIFFERENCE: 2,
  NUMBER_OF_PEOPLE: 3,
};

export const STANDARD_WORK_HOURS_PAR_DAY = 8;

// スタッフ性別
export const STAFF_GENDER = {
  NO_ANSWER: 0,
  MALE: 1,
  FEMALE: 2,
  NON_BINARY: 3,
} as const;
export type StaffGender = (typeof STAFF_GENDER)[keyof typeof STAFF_GENDER];

// FIXME: StaffGenderSelectOptions -> STAFF_GENDER_SELECT_OPTIONSに直す
export const StaffGenderSelectOptions = [
  { id: STAFF_GENDER.NO_ANSWER, name: '未設定', short_name: '-' },
  { id: STAFF_GENDER.MALE, name: '男', short_name: '男' },
  { id: STAFF_GENDER.FEMALE, name: '女', short_name: '女' },
  { id: STAFF_GENDER.NON_BINARY, name: '他', short_name: '他' },
];

// 工程タイプ
export const TIMETABLE_TYPE = {
  GENERAL: 'general',
  RESTING: 'resting',
} as const;
export type TimetableType = (typeof TIMETABLE_TYPE)[keyof typeof TIMETABLE_TYPE];

// タスク関連
export const TASK_PROGRESS_STATUS_NONE = 0;
export const TASK_PROGRESS_STATUS_ONGOING = 1;
export const TASK_PROGRESS_STATUS_COMPLETED = 2;
export const TASK_PROGRESS_STATUS_SKIP = 99;
export const TASK_ALARM_STATUS_NONE = 0;
export const TASK_ALARM_STATUS_WARN = 1;
export const TASK_ALARM_STATUS_DANGER = 2;

// LogiCoredataのURL(環境毎)
export const LOGI_COREDATA_URLS = {
  development: 'http://localhost:8090',
  staging: 'https://stg.logi-coredata.kurando.io',
  stg: 'https://stg.templc.kurando.io',
  production: 'https://logi-coredata.kurando.io',
};

export const CYCLE = { daily: '毎日', weekly: '毎週', monthly: '毎月', exception: 'なし' };

// 見積売上タイプ
export const ESTIMATED_REVENUE_ITEM_TYPE_DISPLAY = {
  direct_input: '0:売上を直接入力',
  calc_by_quantity: '1:物量から計算',
  calc_by_activity_quantity: '2:数量から計算',
  reference_value: '3:参考項目(入力)',
};

// 見積費用タイプ
export const ESTIMATED_COST_ITEM_TYPE_DISPLAY = {
  direct_input: '0:費用を直接入力',
  calc_by_required_hours: '1:工数から計算',
  calc_by_quantity: '2:数量から計算',
  calc_by_rate: '3:売上物量から計算',
};

// 予実モード
export const SCHED_ACTUAL_MODE = {
  SCHEDULED: 1,
  ACTUAL: 2,
  EITHER: 3,
} as const;
export type SchedActualMode = (typeof SCHED_ACTUAL_MODE)[keyof typeof SCHED_ACTUAL_MODE];

export const SchedActualModeSelectOptions = [
  { id: SCHED_ACTUAL_MODE.SCHEDULED, name: '確定シフト' },
  { id: SCHED_ACTUAL_MODE.ACTUAL, name: '出勤実績' },
  { id: SCHED_ACTUAL_MODE.EITHER, name: '確定シフト・出勤実績' },
];

// シフトは「仮シフト」「確定シフト」「実績シフト」の3種類がある
export const SHIFT_PHASE = {
  PROVISIONAL: 1,
  SCHEDULED: 2,
  ACTUAL: 3,
} as const;
export type ShiftPhase = (typeof SHIFT_PHASE)[keyof typeof SHIFT_PHASE];

export const ShiftDisplayModeSelectOptions = [
  { id: SHIFT_PHASE.PROVISIONAL, name: '仮シフト' },
  { id: SHIFT_PHASE.SCHEDULED, name: '確定シフト' },
  { id: SHIFT_PHASE.ACTUAL, name: '出勤実績' },
];

// 応援調整メモタイプ
export const RESCUE_ADJUSTMENT_MEMO_OWNER_TYPE = {
  WORKPLACE: 'workplace',
  BUDGET_GROUP: 'budget_group',
  STAFF_AGENCY: 'staff_agency',
} as const;
export type RescueAdjustmentMemoOwnerType =
  (typeof RESCUE_ADJUSTMENT_MEMO_OWNER_TYPE)[keyof typeof RESCUE_ADJUSTMENT_MEMO_OWNER_TYPE];

// 応援調整リソース調整状況 応援元タイプ
export const RESCUE_ADJUSTMENT_STATUS_FROM_TYPE = {
  BUDGET_GROUP: 1,
  STAFF_AGENCY: 2,
} as const;
export type RescueAdjustmentStatusFromType =
  (typeof RESCUE_ADJUSTMENT_STATUS_FROM_TYPE)[keyof typeof RESCUE_ADJUSTMENT_STATUS_FROM_TYPE];

// 表示項目
export const SHOW_MODE = {
  ACTUAL: 1,
  SCHEDULED: 2,
  SCHEDULED_ACTUAL: 3,
} as const;
export type ShowMode = (typeof SHOW_MODE)[keyof typeof SHOW_MODE];
export const ShowModeOptions = [
  { id: SHOW_MODE.SCHEDULED, name: '予定' },
  { id: SHOW_MODE.ACTUAL, name: '実績' },
  { id: SHOW_MODE.SCHEDULED_ACTUAL, name: '予定+実績' },
];
export const isValidShowMode = (value: unknown): value is ShowMode => {
  if (typeof value !== 'number') {
    return false;
  }
  return Object.values<number>(SHOW_MODE).includes(value);
};

// 人数表示
export const HEADCOUNT_MODE = {
  HEADCOUNT: 1,
  MAN_HOUR: 2,
} as const;
export type HeadcountMode = (typeof HEADCOUNT_MODE)[keyof typeof HEADCOUNT_MODE];
export const HeadcountModeOptions = [
  { id: HEADCOUNT_MODE.HEADCOUNT, name: '人数' },
  { id: HEADCOUNT_MODE.MAN_HOUR, name: '人時' },
];
export const isValidHeadcountMode = (value: unknown): value is HeadcountMode => {
  if (typeof value !== 'number') {
    return false;
  }
  return Object.values<number>(HEADCOUNT_MODE).includes(value);
};

// 当日ボード　表示画面
export const DAILY_BOARD_PAGE = {
  DAILY_BOARD_PREP: 'WorkplaceDailyBoardPrep',
  DAILY_SIMUlATION: 'WorkplaceDailySimulation',
  DAILY_BOARD_REVIEW: 'WorkplaceDailyBoardReview',
} as const;
export type DailyBoardPage = (typeof DAILY_BOARD_PAGE)[keyof typeof DAILY_BOARD_PAGE];
export const dailyBoardPageOptions: { value: DailyBoardPage; label: string }[] = [
  { value: DAILY_BOARD_PAGE.DAILY_BOARD_PREP, label: '計画' },
  { value: DAILY_BOARD_PAGE.DAILY_SIMUlATION, label: '当日' },
  { value: DAILY_BOARD_PAGE.DAILY_BOARD_REVIEW, label: '実績' },
];

// OAuth Callback用ダミーURLパス
export const OAUTH_PREDEFINED_CALLBACK_URL_PATH = '/settings/oauth/callback';
