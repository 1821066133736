import { DeepReadonly } from '@vue/composition-api';
import { TimetableType } from 'src/consts';
import { TimeInteger } from 'src/models/common';
import { ProgressHeader } from 'src/models/progressHeader';
import { TimeRange } from 'src/values/TimeRange';
import { TimetableMaster } from 'src/models/timetableMaster';

/**
 * 各工程の概要情報
 * 表で言うところの左側の情報
 */
export type Header = {
  masterId: number;
  name: string | null;
  timetableType: TimetableType;
  backgroundColor: string | undefined;
  dispOrder: number | null;
  scheduledQuantity: number | null; // 予定数量
  needManHours: number | null; // 必要人時
  targetCompletionTime: TimeInteger | null; // （終了）目標
  simulatedCompletionTime: TimeInteger | null; // 終了予測
  totalProductivity: number | null; // 当日生産性
  targetProductivity: number | null; // 標準（生産性）
  planedProductivity: number | null; // 生産性
  break1TimeRange: TimeRange | null; // 休憩1
  break2TimeRange: TimeRange | null; // 休憩2
};

/**
 * 単位時間あたりの作業人数情報の基本形
 * 画面における1ブロックに相当する
 */
type BaseTimeBlock = {
  displayTime: string;
  headcount: number;
  timetableMasterId: number;
  isSimulated: boolean;
  isHeadcountShrunk?: boolean;
};

/**
 * 一般工程におけるタイムブロックの基本形
 */
type BaseTimetableTimeBlock = BaseTimeBlock & {
  // その時間において休憩しているとみなす仮想的な人数
  onBreakHeadcount: number;
  isBreak: boolean;
};

/**
 * 一般工程（作業時間帯）のタイムブロック
 */
export type TimetableWorkingTimeBlock = BaseTimetableTimeBlock & {
  isBreak: false;
};

/**
 * 一般工程（休憩時間帯）のタイムブロック
 */
export type TimetableBreakTimeBlock = BaseTimetableTimeBlock & {
  isBreak: true;
  // 休憩時間帯であっても作業時間として振る舞わせたいときtrueに設定する
  // 休憩時間帯の人数を変更されたときに使用される想定
  behavingAsWorkTime: boolean;
  // 実質的にそのブロックに何人いるかを保持する
  // 休憩人数をどれだけ増減させればよいかを計算するために使用する
  simulatedHeadcount: number;
};

export type TimetableTimeBlock = TimetableWorkingTimeBlock | TimetableBreakTimeBlock;

/**
 * 休憩工程のタイムブロック
 */
export type RestingTimetableTimeBlock = BaseTimeBlock & {
  standbyHeadcount: number;
};

/**
 * 特定の工程によらない作業人数情報を表す
 * 画面における合計行と割当なし行の1ブロックに相当する
 */
export type SupportTimeBlock = BaseTimeBlock;

/**
 * 各種タイムブロックのユニオン型
 * 特定のタイムブロックによらない処理を行う際に使用する
 */
export type TimeBlock = TimetableTimeBlock | RestingTimetableTimeBlock | SupportTimeBlock;

/**
 * 指定されたタイムブロック型を抽象化して返す
 *   TimetableWorkingTimeBlock, TimetableBreakTimeBlock → TimetableTimeBlock
 *   それ以外の型 → 元の型
 * 一般工程行のタイムブロックには作業時間帯と休憩時間帯が混在しているため、具体的な型を抽象化して処理する必要がある場合に使用する
 */
export type AbstractTimeBlock<TB extends TimeBlock> = TB extends TimetableTimeBlock ? TimetableTimeBlock : TB;

export const isTimetableTimeBlock = (block: TimeBlock): block is TimetableTimeBlock => {
  return (
    'onBreakHeadcount' in block &&
    typeof block.onBreakHeadcount === 'number' &&
    'isBreak' in block &&
    (!block.isBreak ||
      ('behavingAsWorkTime' in block &&
        typeof block.behavingAsWorkTime === 'boolean' &&
        'simulatedHeadcount' in block &&
        typeof block.simulatedHeadcount === 'number'))
  );
};

export const isRestingTimetableTimeBlock = (block: TimeBlock): block is RestingTimetableTimeBlock => {
  return 'standbyHeadcount' in block && typeof block.standbyHeadcount === 'number';
};

export const isSupportTimeBlock = (block: TimeBlock): block is SupportTimeBlock => {
  return !isTimetableTimeBlock(block) && !isRestingTimetableTimeBlock(block);
};

/**
 * 1時間ごとの作業人数情報を配列として保持する
 * 画面における1時間あたりのブロック（15分ごとなら4ブロック分）に相当する
 */
export type HourBlock<TB extends TimeBlock = TimeBlock> = {
  unitTimeBlocks: TB[];
};

/**
 * 進捗履歴
 */
export type ProgressHistory = {
  quantity: number;
  totalQuantity: number;
  productivity: number;
  startTime: TimeInteger;
  endTime: TimeInteger;
  isBlankCell?: boolean;
  isSimulated?: boolean;
};

/**
 * 一般工程の概要、1日分の作業人数と進捗履歴を保持する
 * 画面における一般工程の1行に相当する
 */
export type TimetableRow = {
  header: Header;
  hourBlocks: HourBlock<TimetableTimeBlock>[];
  progressHeader: DeepReadonly<ProgressHeader>;
  progressHistories: ProgressHistory[];
};

/**
 * 休憩工程の概要、1日分の作業人数を保持する
 * 画面における休憩工程の1行に相当する
 */
export type RestingTimetableRow = {
  header: Pick<Header, 'masterId' | 'name' | 'backgroundColor'>;
  hourBlocks: HourBlock<RestingTimetableTimeBlock>[];
  progressHeader: DeepReadonly<ProgressHeader>;
};

export type TargetDateProgressHeader = {
  id: number;
  workplace_id: number;
  budget_group_id: number;
  timetable_master_id: number;
  dt: Date;
  start_time: number | null;
  end_time: number | null;
  forecasted_completion_time: number | null;
  target_completion_time: number | null;
  actual_completion_time: number | null;
  forecasted_remaining_quantity: number | null;
  scheduled_quantity: number | null;
  result_quantity: number | null;
  scheduled_man_hours: number | null;
  result_man_hours: number | null;
  latest_productivity: number | null;
  total_productivity: number | null;
  target_productivity: number | null;
  memo: string;
};

// 指定日分のProgressHeaderのみを保持する
export interface TimetableMasterWithProgressHeader extends TimetableMaster {
  progressHeader?: TargetDateProgressHeader;
}

export type SaveProgressDetailCandidate = {
  timetableMasters: TimetableMasterWithProgressHeader[];
};

export type CommonSettingState = {
  targetClockOutTime: TimeInteger | null; // 勤務終了目標
};
