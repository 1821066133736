














import { defineComponent, type PropType } from '@vue/composition-api';
import Notification from './components/Notification.vue';

export default defineComponent({
  components: {
    Notification,
  },
  props: {
    timetableMasterName: {
      type: String as PropType<string | null>,
      default: null,
    },
    startTime: {
      type: Number,
      required: true,
    },
    productivityTo: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const message = 'に生産性を変更しました。';
    return {
      message,
    };
  },
});
