















import { defineComponent, computed, type PropType } from '@vue/composition-api';

type Status = 'blank' | 'actual' | 'simulated' | 'unsimulated';

export default defineComponent({
  props: {
    status: {
      type: String as PropType<Status>,
      required: true,
    },
    isMergedWithNextQuantityCell: {
      type: Boolean,
      required: false,
      default: false,
    },
    isMergedWithNextProductivityCell: {
      type: Boolean,
      required: false,
      default: false,
    },
    isQuantityTarget: {
      type: Boolean,
      required: false,
      default: false,
    },
    isProductivityTarget: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const quantityClasses = computed(() => ({
      [props.status]: true,
      br: !props.isMergedWithNextQuantityCell,
      'is-target': props.isQuantityTarget,
    }));

    const productivityClasses = computed(() => ({
      [props.status]: true,
      br: !props.isMergedWithNextProductivityCell,
      'is-target': props.isProductivityTarget,
    }));

    const onQuantityClick = (event: MouseEvent) => {
      emit('quantity-click', event);
    };

    const onProductivityClick = (event: MouseEvent) => {
      emit('productivity-click', event);
    };

    return {
      quantityClasses,
      productivityClasses,
      onQuantityClick,
      onProductivityClick,
    };
  },
});
