






















































































import { defineComponent, type PropType } from '@vue/composition-api';
import { roundToOneDecimal } from 'src/util/numbers';
import type { TimetableMaster } from 'src/models/timetableMaster';
import ProgressCells from './ProgressCells.vue';
import ProgressPopover from './ProgressPopover.vue';
import {
  isBlankProgressSection,
  isActualProgressSection,
  isSimulatedProgressSection,
  isUnsimulatedProgressSection,
  type ProgressSection,
  type HourSegment,
  type QuarterSegment,
  type UnsimulatedProgressSection,
  type SimulatedProgressSection,
} from '../types';
import { isAtLeast30Minutes, isAtLeast30MinutesByTimeRange } from '../dailySimulationHelper';
import { useProgressSection } from '../composables/useProgressSection';
import { useQuantityUpdatePopover } from '../composables/UpdatePopover/useQuantityUpdatePopover';
import { useProductivityUpdatePopover } from '../composables/UpdatePopover/useProductivityUpdatePopover';

export default defineComponent({
  components: {
    ProgressCells,
    ProgressPopover,
  },
  props: {
    timetableMaster: {
      type: Object as PropType<TimetableMaster>,
      required: true,
    },
    progressSection: {
      type: Object as PropType<ProgressSection>,
      required: true,
    },
  },
  setup(props) {
    const {
      timetableMaster: quantityTimetableMaster,
      quarterSegment: quantityQuarterSegment,
      showQuantityUpdatePopover,
    } = useQuantityUpdatePopover();
    const {
      timetableMaster: productivityTimetableMaster,
      quarterSegment: productivityQuarterSegment,
      showProductivityUpdatePopover,
    } = useProductivityUpdatePopover();

    const onQuantityClick = (
      event: MouseEvent,
      progressSection: SimulatedProgressSection,
      hourSegment: HourSegment,
      quarterSegment: QuarterSegment,
    ) => {
      const quarterSegments = progressSection.hourSegments.flatMap((hourSegment) => hourSegment.quarterSegments);
      const progressSectionQuarterTime = {
        start: quarterSegments[0].displayTime,
        target: quarterSegment.displayTime,
        end: quarterSegments[quarterSegments.length - 1].displayTime,
      };
      const isSectionEndUpdate = progressSectionQuarterTime.end === progressSectionQuarterTime.target;
      const initialValue = isSectionEndUpdate ? roundToOneDecimal(hourSegment.totalQuantity) : null;
      showQuantityUpdatePopover({
        event,
        timetableMaster: props.timetableMaster,
        quarterSegment,
        progressSectionQuarterTime,
        initialValue,
      });
    };

    const onProductivityClick = (
      event: MouseEvent,
      progressSection: UnsimulatedProgressSection | SimulatedProgressSection,
      quarterSegment: QuarterSegment,
    ) => {
      const quarterSegments = isUnsimulatedProgressSection(progressSection)
        ? progressSection.quarterSegments
        : progressSection.hourSegments.flatMap((hourSegment) => hourSegment.quarterSegments);
      const progressSectionQuarterTime = {
        start: quarterSegments[0].displayTime,
        target: quarterSegment.displayTime,
        end: quarterSegments[quarterSegments.length - 1].displayTime,
      };
      showProductivityUpdatePopover({
        event,
        timetableMaster: props.timetableMaster,
        quarterSegment,
        progressSectionQuarterTime,
        initialValue: roundToOneDecimal(progressSection.productivity),
      });
    };

    const isQuantityTarget = (timetableMasterId: number, displayTime: string) => {
      return (
        timetableMasterId === quantityTimetableMaster.value?.id &&
        displayTime === quantityQuarterSegment.value?.displayTime
      );
    };

    const isProductivityTarget = (timetableMasterId: number, displayTime: string) => {
      return (
        timetableMasterId === productivityTimetableMaster.value?.id &&
        displayTime === productivityQuarterSegment.value?.displayTime
      );
    };

    const { getGridStylesBySegments } = useProgressSection();
    return {
      isAtLeast30Minutes,
      isAtLeast30MinutesByTimeRange,
      isBlankProgressSection,
      isActualProgressSection,
      isSimulatedProgressSection,
      isUnsimulatedProgressSection,
      isQuantityTarget,
      isProductivityTarget,
      onQuantityClick,
      onProductivityClick,
      getGridStylesBySegments,
      roundToOneDecimal,
    };
  },
});
