












import { defineComponent } from '@vue/composition-api';
import { useUpdateProgressDetail } from '../../composables/useUpdateProgressDetail';
import { ProgressDetailInputModal, type ProgressDetailInput } from '../ProgressDetailInputModal';

export default defineComponent({
  components: {
    ProgressDetailInputModal,
  },
  setup() {
    const {
      progressHeader,
      progressDetail,
      showsProgressDetailUpdateModal,
      hideProgressDetailUpdateModal,
      updateProgressDetail,
    } = useUpdateProgressDetail();
    const handleSubmit = async (progressDetailInput: ProgressDetailInput) => {
      if (progressDetail.value !== null) {
        await updateProgressDetail({
          ...progressDetail.value,
          ...progressDetailInput,
        });
      }
    };

    return {
      progressHeader,
      progressDetail,
      showsProgressDetailUpdateModal,
      hideProgressDetailUpdateModal,
      handleSubmit,
    };
  },
});
