





























import { defineComponent, ref, watch, type PropType } from '@vue/composition-api';
import { TextInput } from 'src/components/UIComponents/Inputs/TextInput';
import { type TimeInteger } from 'src/models/common';
import { unpackTimeIntegerToString, packToTimeInteger } from 'src/util/datetime';
import { isValidHours } from './isValidHours';
import { isValidMinutes } from './isValidMinutes';

export default defineComponent({
  components: {
    TextInput,
  },
  props: {
    value: {
      type: Number as PropType<TimeInteger | null>,
      default: null,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    focusHoursOnMount: {
      type: Boolean,
      default: false,
    },
    focusMinutesOnMount: {
      type: Boolean,
      default: false,
    },
    /**
     * VeeValidate(v2)のValidationProviderにNaNを渡すとハングするので、これを回避するためにNaNの代わりに使用する数値を指定する。
     * ValidationProviderに設定したrulesの範囲外の数値を指定することを想定している。
     * このコンポーネントの値はマイナス値にならないので、基本的にはrulesにmin_value:0、valueInsteadOfNanに-1を指定すればよい。
     * @see {@link https://github.com/logaretm/vee-validate/issues/2765}
     */
    valueInsteadOfNan: {
      type: Number as PropType<number | null>,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const [h = '', m = ''] = props.value !== null ? unpackTimeIntegerToString(props.value) : [];
    const hours = ref(h);
    const minutes = ref(m);
    const handleFocusin = () => {
      context.emit('focus');
    };
    const handleFocusout = (e: FocusEvent) => {
      const currentTarget = e.currentTarget as HTMLDivElement;
      const relatedTarget = e.relatedTarget;
      if (!(relatedTarget instanceof Node) || !currentTarget.contains(relatedTarget)) {
        context.emit('blur');
      }
    };
    const getNormalizedValue = (hours: string, minutes: string) => {
      if (isValidHours(hours) && isValidMinutes(minutes)) {
        return packToTimeInteger(Number(hours), Number(minutes), 0);
      }
      return `${hours}${minutes}` === '' ? null : props.valueInsteadOfNan ?? NaN;
    };

    watch([hours, minutes], ([hours, minutes]) => {
      context.emit('input', getNormalizedValue(hours, minutes));
    });

    watch(
      () => props.value,
      (newValue) => {
        const currentValue = getNormalizedValue(hours.value, minutes.value);
        if (newValue !== currentValue && !Number.isNaN(newValue)) {
          const [h = '', m = ''] = newValue !== null ? unpackTimeIntegerToString(newValue) : [];
          hours.value = h;
          minutes.value = m;
        }
      },
    );

    return {
      hours,
      minutes,
      handleFocusin,
      handleFocusout,
    };
  },
});
