import { format } from 'date-fns';
import { type CsvRow } from 'src/components/UIComponents/Buttons/CsvDownloadButton/types';
import { type MonthlyPlanState } from '../types';

function formatNumber(value: number | null, decimalPlaces: number = 0): string | number {
  if (value === null) {
    return '';
  }
  const factor = 10 ** decimalPlaces;
  return Math.round(value * factor) / factor;
}

export function buildCsvData({ targetDates, dailyPlans, tasks }: MonthlyPlanState): CsvRow[] {
  return [
    ['L1', 'L2', 'L3', 'L4', 'L5', ...targetDates.map((v) => format(v, 'yyyy/MM/dd'))],
    ['全体', '-', '-', '-', '必要工数', ...dailyPlans.map((v) => formatNumber(v.requiredManHours, 1))],
    ['全体', '-', '-', '-', '予定工数', ...dailyPlans.map((v) => formatNumber(v.plannedManHours, 1))],
    ['全体', '-', '-', '-', '出勤実績', ...dailyPlans.map((v) => formatNumber(v.totalActualManHours, 1))],
    [
      'シフト管理対象別',
      'あり',
      '-',
      '-',
      '予定工数',
      ...dailyPlans.map((v) => formatNumber(v.regularPlannedManHours, 1)),
    ],
    [
      'シフト管理対象別',
      'あり',
      '-',
      '-',
      '出勤実績',
      ...dailyPlans.map((v) => formatNumber(v.regularActualManHours, 1)),
    ],
    ['シフト管理対象別', 'なし', '-', '-', '予定工数', ...dailyPlans.map((v) => formatNumber(v.totalSpot, 1))],
    ['シフト管理対象別', 'なし', '-', '-', '登録バイト', ...dailyPlans.map((v) => formatNumber(v.spot1ManHours, 1))],
    ['シフト管理対象別', 'なし', '-', '-', 'アプリ募集', ...dailyPlans.map((v) => formatNumber(v.spot2ManHours, 1))],
    ['シフト管理対象別', 'なし', '-', '-', '派遣', ...dailyPlans.map((v) => formatNumber(v.spot3ManHours, 1))],
    ['シフト管理対象別', 'なし', '-', '-', '応援', ...dailyPlans.map((v) => formatNumber(v.spot4ManHours, 1))],
    ['シフト管理対象別', 'なし', '-', '-', '出勤実績', ...dailyPlans.map((v) => formatNumber(v.spotActualManHours, 1))],
    ...tasks.flatMap((task, i) => {
      const segments = task.segments;
      return [
        [
          'シフトグループ別',
          task.name,
          '-',
          '-',
          '必要工数',
          ...dailyPlans.map((v) => formatNumber(v.tasks[i].requiredManHours, 1)),
        ],
        [
          'シフトグループ別',
          task.name,
          '-',
          '-',
          'シフト',
          ...dailyPlans.map((v) => formatNumber(v.tasks[i].plannedManHours, 1)),
        ],
        [
          'シフトグループ別',
          task.name,
          '-',
          '-',
          '出勤実績',
          ...dailyPlans.map((v) => formatNumber(v.tasks[i].actualManHours, 1)),
        ],
        [
          'シフトグループ別',
          task.name,
          '-',
          '-',
          'うち時間外',
          ...dailyPlans.map((v) => formatNumber(v.tasks[i].overtimeWorkHours, 1)),
        ],
        ...segments.flatMap((segment, j) => [
          segment.is_quantity_used
            ? [
                'シフトグループ別',
                task.name,
                segment.name,
                '数量',
                '初期',
                ...dailyPlans.map((v) => formatNumber(v.tasks[i].segments[j].plannedInitialQuantity)),
              ]
            : [],
          segment.is_quantity_used
            ? [
                'シフトグループ別',
                task.name,
                segment.name,
                '数量',
                '直前',
                ...dailyPlans.map((v) => formatNumber(v.tasks[i].segments[j].plannedFinalQuantity)),
              ]
            : [],
          segment.is_quantity_used
            ? [
                'シフトグループ別',
                task.name,
                segment.name,
                '数量',
                '実績',
                ...dailyPlans.map((v) => formatNumber(v.tasks[i].segments[j].actualQuantity)),
              ]
            : [],
          segment.is_quantity_used
            ? [
                'シフトグループ別',
                task.name,
                segment.name,
                '生産性',
                '予定',
                ...dailyPlans.map((v) => formatNumber(v.tasks[i].segments[j].plannedProductivity, 1)),
              ]
            : [],
          segment.is_quantity_used
            ? [
                'シフトグループ別',
                task.name,
                segment.name,
                '生産性',
                '実績',
                ...dailyPlans.map((v) => formatNumber(v.tasks[i].segments[j].actualProductivity, 1)),
              ]
            : [],
          [
            'シフトグループ別',
            task.name,
            segment.name,
            '工数',
            '必要',
            ...dailyPlans.map((v) => formatNumber(v.tasks[i].segments[j].requiredManHours, 1)),
          ],
          [
            'シフトグループ別',
            task.name,
            segment.name,
            '工数',
            '作業実績',
            ...dailyPlans.map((v) => formatNumber(v.tasks[i].segments[j].actualManHours, 1)),
          ],
        ]),
      ];
    }),
    ['収支', '売上', '-', '-', '実績', ...dailyPlans.map((v) => formatNumber(v.actualRevenue))],
    ['収支', '費用', '-', '-', '実績', ...dailyPlans.map((v) => formatNumber(v.actualCost))],
    ['収支', '利益', '-', '-', '実績', ...dailyPlans.map((v) => formatNumber(v.actualProfit))],
    ['収支', '間接収支', '-', '-', '実績', ...dailyPlans.map((v) => formatNumber(v.indirectBalance))],
  ].filter((item) => !(Array.isArray(item) && item.length === 0));
}
