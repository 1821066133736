import { computed, reactive, watch } from '@vue/composition-api';
import { createInjection } from 'src/util/createInjection';
import { SHOW_MODE, HEADCOUNT_MODE, isValidShowMode, isValidHeadcountMode, ShowMode } from 'src/consts';
import { MONTHLY_PLAN_DISPLAY_TARGET as DISPLAY_TARGET } from '../const';
import { MonthlyPlanDisplayState } from '../types';
import { useMonthlyPlanUrlParameters } from './useMonthlyPlanUrlParameters';
import { isExist } from 'src/util/isExist';
import { useSearchConditions } from './useSearchConditions';

type InjectionValue = {
  state: MonthlyPlanDisplayState;
  changeURLQuery: () => Promise<void>;
  changeShowMode: (showMode: ShowMode) => void;
  waitLoading: () => void;
  finishLoading: () => void;
};

const { provide, inject } = createInjection<InjectionValue>('useDisplayConditions');

export function useDisplayConditionsProvider(): void {
  const { budgetGroup } = useSearchConditions();
  const urlParameters = useMonthlyPlanUrlParameters();
  const { show_mode: showMode, headcount_mode: headcountMode } = urlParameters.getUrlParameters();

  const state: MonthlyPlanDisplayState = reactive({
    showMode: isValidShowMode(showMode) ? showMode : SHOW_MODE.ACTUAL,
    headcountMode: isValidHeadcountMode(headcountMode) ? headcountMode : HEADCOUNT_MODE.MAN_HOUR,
    selectedDisplayTargets: [],
    shouldShowActual: computed(
      () => state.showMode === SHOW_MODE.ACTUAL || state.showMode === SHOW_MODE.SCHEDULED_ACTUAL,
    ),
    shouldShowScheduled: computed(
      () => state.showMode === SHOW_MODE.SCHEDULED || state.showMode === SHOW_MODE.SCHEDULED_ACTUAL,
    ),
    shouldShowInitialQuantity: computed(
      () =>
        state.selectedDisplayTargets.includes(DISPLAY_TARGET.INITIAL_QUANTITY) ||
        (state.shouldShowScheduled && !state.selectedDisplayTargets.length),
    ),
    shouldShowFinalQuantity: computed(
      () =>
        state.selectedDisplayTargets.includes(DISPLAY_TARGET.FINAL_QUANTITY) ||
        (state.shouldShowScheduled && !state.selectedDisplayTargets.length),
    ),
    shouldShowActualQuantity: computed(
      () =>
        state.selectedDisplayTargets.includes(DISPLAY_TARGET.ACTUAL_QUANTITY) ||
        (state.shouldShowActual && !state.selectedDisplayTargets.length),
    ),
    shouldShowQuantity: computed(
      () => state.shouldShowInitialQuantity || state.shouldShowFinalQuantity || state.shouldShowActualQuantity,
    ),
    shouldShowPlannedProductivity: computed(
      () =>
        state.selectedDisplayTargets.includes(DISPLAY_TARGET.PLANNED_PRODUCTIVITY) ||
        (state.shouldShowScheduled && !state.selectedDisplayTargets.length),
    ),
    shouldShowActualProductivity: computed(
      () =>
        state.selectedDisplayTargets.includes(DISPLAY_TARGET.ACTUAL_PRODUCTIVITY) ||
        (state.shouldShowActual && !state.selectedDisplayTargets.length),
    ),
    shouldShowProductivity: computed(() => state.shouldShowPlannedProductivity || state.shouldShowActualProductivity),
    shouldShowRequiredManHours: computed(
      () =>
        state.selectedDisplayTargets.includes(DISPLAY_TARGET.REQUIRED_MAN_HOURS) ||
        (state.shouldShowScheduled && !state.selectedDisplayTargets.length),
    ),
    shouldShowPlannedManHours: computed(
      () =>
        state.selectedDisplayTargets.includes(DISPLAY_TARGET.PLANNED_MAN_HOURS) ||
        (state.shouldShowScheduled && !state.selectedDisplayTargets.length),
    ),
    shouldShowActualManHours: computed(
      () =>
        state.selectedDisplayTargets.includes(DISPLAY_TARGET.ACTUAL_MAN_HOURS) ||
        (state.shouldShowActual && !state.selectedDisplayTargets.length),
    ),
    shouldShowOvertimeWorkHours: computed(
      () =>
        state.selectedDisplayTargets.includes(DISPLAY_TARGET.OVERTIME_WORK_HOURS) ||
        (state.shouldShowActual && !state.selectedDisplayTargets.length),
    ),
    shouldShowManHours: computed(
      () =>
        state.shouldShowRequiredManHours ||
        state.shouldShowPlannedManHours ||
        state.shouldShowActualManHours ||
        state.shouldShowOvertimeWorkHours,
    ),
    shouldShowSegmentManHours: computed(() => state.shouldShowRequiredManHours || state.shouldShowActualManHours),
    shouldShowSegmentContent: computed(
      () => state.shouldShowQuantity || state.shouldShowProductivity || state.shouldShowSegmentManHours,
    ),
    shouldShowBlankCell: computed(
      () =>
        !state.shouldShowRequiredManHours &&
        !state.shouldShowPlannedManHours &&
        !state.shouldShowActualManHours &&
        !state.shouldShowOvertimeWorkHours,
    ),
    isDisplayedManHour: computed(() => state.headcountMode === HEADCOUNT_MODE.MAN_HOUR),
    isShowActualData: false,
    isSummaryDisplayed: false,
    isLoading: false,
  });

  const changeShowMode = (showMode: ShowMode) => {
    state.showMode = showMode;
    state.selectedDisplayTargets = [];
  };

  const changeURLQuery = async () => {
    if (!isExist(budgetGroup.value)) {
      return;
    }
    await urlParameters.setUrlParameters({
      budget_group_id: budgetGroup.value.id,
      show_mode: state.showMode,
      headcount_mode: state.headcountMode,
    });
  };

  const waitLoading = (): void => {
    state.isLoading = true;
  };

  const finishLoading = (): void => {
    state.isLoading = false;
  };

  watch(
    () => [state.showMode, state.headcountMode],
    () => {
      changeURLQuery();
    },
  );

  provide({
    state,
    changeURLQuery,
    changeShowMode,
    waitLoading,
    finishLoading,
  });
}

export function useDisplayConditions(): InjectionValue {
  return inject();
}
