import userApi from 'src/apis/user';

const SET_LOGIN_USER = 'SET_LOGIN_USER';
const CLEAR_LOGIN_USER = 'CLEAR_LOGIN_USER';

const _initialState = {
  id: -1,
  email: '',
  family_name: '',
  first_name: '',
  job_title: '',
  role: '',
  has_role_super_admin: false,
  has_role_admin: false,
  has_role_member: false,
  is_super_admin: false,
  is_admin: false,
  is_member: false,
  env: '',
  logi_coredata_url: '',
  users_workplaces: [],
  workplaceRoleMap: {},
};

const state = JSON.parse(JSON.stringify(_initialState));

const getters = {
  isLoggedIn: (state) => state.id !== -1,
  id: (state) => state.id,
  email: (state) => state.email,
  fullName: (state) => `${state.family_name} ${state.first_name}`,
  env: (state) => state.env,
  logiCoredataUrl: (state) => state.logi_coredata_url,
  hasRoleSuperAdmin: (state) => state.has_role_super_admin,
  workplaceRoleMap: (state) => state.workplaceRoleMap,
};

let promiseOfGetMe = null;
const actions = {
  getMe({ commit, state, getters }) {
    if (getters.isLoggedIn) {
      return Promise.resolve(Object.assign({}, state));
    }
    if (!promiseOfGetMe) {
      promiseOfGetMe = userApi.getMe();
    }
    return promiseOfGetMe.then(({ data }) => {
      commit(SET_LOGIN_USER, { data });
      return Object.assign({}, state);
    });
  },
  getMeRefresh({ commit, state }) {
    return userApi.getMe().then(({ data }) => {
      commit(SET_LOGIN_USER, { data });
      return Object.assign({}, state);
    });
  },
  setMe({ commit }, data) {
    commit(SET_LOGIN_USER, { data });
  },
  login({ commit, state }, data) {
    return userApi.login(data).then((res) => {
      const token = res.headers.authorization.split(' ')[1];
      commit(SET_LOGIN_USER, { data: res.data });
      return { access_token: token };
    });
  },
  logout({ commit }) {
    return userApi.logout().then(() => {
      commit(CLEAR_LOGIN_USER);
    });
  },
};

const mutations = {
  [SET_LOGIN_USER](state, { data }) {
    Object.keys(data).forEach((k) => {
      if (k === 'users_workplaces') {
        state.workplaceRoleMap = data[k].reduce((acc, e) => {
          acc[e.workplace_id] = {
            role: e.role,
            status: e.status,
          };
          return acc;
        }, {});
      }
      state[k] = data[k];
    });
  },
  [CLEAR_LOGIN_USER](state) {
    const initialState = JSON.parse(JSON.stringify(_initialState));
    Object.keys(initialState).forEach((k) => {
      state[k] = initialState[k];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
