import { ref, readonly, watch, onBeforeUnmount, type Ref } from '@vue/composition-api';
import { format } from 'date-fns';
import progressHeaderApi from 'src/apis/progressHeader';
import timetableGroupMasterApi from 'src/apis/workplace_masters/timetable_group_master';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { type BudgetGroup } from 'src/models/budgetGroup';
import { type TimetableLabel } from 'src/models/timetableLabel';
import { convertProgressHeaderPerformanceBoardListIndexResponse, type ProgressHeader } from 'src/models/progressHeader';
import { createInjection } from 'src/util/createInjection';
import { createTimer } from 'src/util/createTimer';
import { TimetableGroupMasterWithProgressHeaders } from 'src/models/timetableGroupMaster';

type Params = {
  workplaceId: number;
  dt: Ref<Date>;
  budgetGroup: Ref<BudgetGroup | null>;
  timetableLabels: Ref<TimetableLabel[]>;
};

type InjectionValue = {
  progressHeaders: Ref<ProgressHeader[]>;
  timetableGroupMasterWithProgressHeaders: Ref<TimetableGroupMasterWithProgressHeaders[]>;
  canSearch: Ref<boolean>;
  canReload: Ref<boolean>;
  lastUpdatedAt: Ref<string>;
  productivityGraph: Ref<boolean>;
  hidesCompletedProgressHeaders: Ref<boolean>;
  hidesEmptyQuantityProgressHeaders: Ref<boolean>;
  filterProgressHeaders: (progressHeaders: ProgressHeader[]) => ProgressHeader[];
  fetchProgressHeaders: () => Promise<boolean>;
};

const { provide, inject } = createInjection<InjectionValue>('useProgressHeaders');

async function getProgressHeaders({
  workplaceId,
  dt,
  budgetGroup,
  timetableLabels,
}: {
  workplaceId: number;
  dt: Date;
  budgetGroup: BudgetGroup | null;
  timetableLabels: TimetableLabel[];
}): Promise<ProgressHeader[]> {
  const { data } = await progressHeaderApi.performanceBoardListIndex({
    workplace_id: workplaceId,
    dt: format(dt, 'yyyy-MM-dd'),
    budget_group_id: budgetGroup?.id ?? null,
    timetable_label_ids: timetableLabels.map(({ id }) => id),
  });

  return convertProgressHeaderPerformanceBoardListIndexResponse(data);
}

async function getTimetableGroupMasterWithProgressHeaders({
  workplaceId,
  dt,
  budgetGroup,
  timetableLabels,
}: {
  workplaceId: number;
  dt: Date;
  budgetGroup: BudgetGroup | null;
  timetableLabels: TimetableLabel[];
}): Promise<TimetableGroupMasterWithProgressHeaders[]> {
  const data = await timetableGroupMasterApi.performanceBoardListIndex({
    workplace_id: workplaceId,
    budget_group_id: budgetGroup!.id,
    dt: format(dt, 'yyyy-MM-dd'),
    timetable_label_ids: timetableLabels.map(({ id }) => id),
  });

  return data;
}

function isZeroOrNull(value: number | null) {
  return value === 0 || value === null;
}

function isInProgress(progressHeader: ProgressHeader): boolean {
  return progressHeader.actual_completion_time === null;
}

function hasQuantity(progressHeader: ProgressHeader): boolean {
  return (
    progressHeader.timetable_master.man_hour_only ||
    !isZeroOrNull(progressHeader.scheduled_quantity) ||
    !isZeroOrNull(progressHeader.result_quantity)
  );
}

function createProgressHeadersFilter({
  hidesCompletedProgressHeaders,
  hidesEmptyQuantityProgressHeaders,
}: {
  hidesCompletedProgressHeaders: Ref<boolean>;
  hidesEmptyQuantityProgressHeaders: Ref<boolean>;
}): (progressHeaders: ProgressHeader[]) => ProgressHeader[] {
  return (progressHeaders: ProgressHeader[]) => {
    if (hidesCompletedProgressHeaders.value) {
      progressHeaders = progressHeaders.filter(isInProgress);
    }
    if (hidesEmptyQuantityProgressHeaders.value) {
      progressHeaders = progressHeaders.filter(hasQuantity);
    }
    return progressHeaders;
  };
}

export function useProgressHeadersProvider({ workplaceId, dt, budgetGroup, timetableLabels }: Params): void {
  const errorBoundary = useErrorBoundary();
  const progressHeaders = ref<ProgressHeader[]>([]);
  const timetableGroupMasterWithProgressHeaders = ref<TimetableGroupMasterWithProgressHeaders[]>([]);
  const canSearch = ref(true);
  const shouldReload = ref(false);
  const lastUpdatedAt = ref('');
  const productivityGraph = ref(false);
  const hidesCompletedProgressHeaders = ref(false);
  const hidesEmptyQuantityProgressHeaders = ref(false);
  const filterProgressHeaders = createProgressHeadersFilter({
    hidesCompletedProgressHeaders,
    hidesEmptyQuantityProgressHeaders,
  });
  const fetchProgressHeaders = errorBoundary.wrap(
    async () => {
      try {
        canSearch.value = false;
        shouldReload.value = false;

        const [fetchedProgressHeaders, fetchTimetableGroupMasterWithProgressHeaders] = await Promise.all([
          getProgressHeaders({
            workplaceId,
            dt: dt.value,
            budgetGroup: budgetGroup.value,
            timetableLabels: timetableLabels.value,
          }),
          getTimetableGroupMasterWithProgressHeaders({
            workplaceId,
            dt: dt.value,
            budgetGroup: budgetGroup.value,
            timetableLabels: timetableLabels.value,
          }),
        ]);
        progressHeaders.value = fetchedProgressHeaders;
        timetableGroupMasterWithProgressHeaders.value = fetchTimetableGroupMasterWithProgressHeaders;
        shouldReload.value = true;
        lastUpdatedAt.value = format(new Date(), 'HH:mm:ss');
      } finally {
        canSearch.value = true;
      }
    },
    {
      fallbackMessage: '表示情報の取得に失敗しました',
    },
  );
  const autoReloadTimer = createTimer(fetchProgressHeaders, 5 * 60 * 1000);

  watch([dt, budgetGroup], () => {
    shouldReload.value = false;
  });

  watch([shouldReload], () => {
    if (shouldReload.value) {
      autoReloadTimer.start();
    } else {
      autoReloadTimer.stop();
    }
  });

  onBeforeUnmount(() => {
    autoReloadTimer.stop();
  });

  provide({
    progressHeaders,
    timetableGroupMasterWithProgressHeaders,
    canSearch: readonly(canSearch),
    canReload: readonly(shouldReload),
    lastUpdatedAt: readonly(lastUpdatedAt),
    productivityGraph,
    hidesCompletedProgressHeaders,
    hidesEmptyQuantityProgressHeaders,
    filterProgressHeaders,
    fetchProgressHeaders,
  });
}

export function useProgressHeaders(): InjectionValue {
  return inject();
}
