import { computed, type ComputedRef } from '@vue/composition-api';
import { getInitialProgressDetailStartTime } from 'src/util/progressHeaderHelpers';
import { useProgressHeader } from '../../composables/useProgressHeader';

type ValidationParams = {
  minEndTime: ComputedRef<number>;
};

export function useValidationParams(): ValidationParams {
  const { progressHeader } = useProgressHeader();
  return {
    minEndTime: computed(() => getInitialProgressDetailStartTime(progressHeader.value) + 1),
  };
}
