import { type TimetableMasterExternalSource } from 'src/models/timetableMasterExternalSource';

export function getActivityMasterIds(
  timetableMasterExternalSources: TimetableMasterExternalSource[],
  usageType: TimetableMasterExternalSource['usage_type'],
): TimetableMasterExternalSource['source_id'][] {
  return timetableMasterExternalSources
    .filter((v) => v.source_system === 'logimeter' && v.source_type === 'activity_master' && v.usage_type === usageType)
    .map((v) => v.source_id);
}
