







































































import { defineComponent, ref, computed, type PropType } from '@vue/composition-api';
import { ModalForm, FormGroup, FormItem } from 'src/components/UIComponents/Form';
import { TimeRangeInput } from 'src/components/UIComponents/Inputs/TimeRangeInput';
import { useCustomValidator } from 'src/composables/useCustomValidator';
import { type ProgressHeader, type ProgressDetail } from 'src/models/progressHeader';
import { orDefault } from 'src/util/filters';
import {
  hasTimetableMasterLogimeterActivities,
  getInitialProgressDetailStartTime,
} from 'src/util/progressHeaderHelpers';
import { type TimeRange, createTimeRange } from 'src/values/TimeRange';
import { formatNumber } from '../../utils/filters';
import NumberInput from './NumberInput.vue';
import { type ProgressDetailInput } from './types';
import { useTimeRangeValidator } from 'src/composables/useTimeRangeValidator';

export default defineComponent({
  filters: {
    orDefault,
    formatNumber,
  },
  components: {
    ModalForm,
    FormGroup,
    FormItem,
    NumberInput,
    TimeRangeInput,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
    progressHeader: {
      type: Object as PropType<ProgressHeader>,
      required: true,
    },
    progressDetail: {
      type: Object as PropType<ProgressDetail | null>,
      default: null,
    },
    onSubmit: {
      type: Function as PropType<(progressDetail: ProgressDetailInput) => Promise<unknown>>,
      required: true,
    },
    mode: {
      type: String as PropType<'create' | 'update'>,
      required: true,
    },
  },
  setup(props) {
    const { strictValidator } = useTimeRangeValidator();
    const strictRule = useCustomValidator(strictValidator);
    const timeRangeRules = `required|${strictRule}`;
    const timeRange = ref<TimeRange | null>(
      createTimeRange(
        props.progressDetail?.start_time ?? getInitialProgressDetailStartTime(props.progressHeader),
        props.progressDetail?.end_time ?? null,
      ),
    );
    const isStartDisabled = props.mode === 'create' && props.progressHeader.progress_details.length > 0;
    const quantity = ref(props.progressDetail?.quantity ?? null);
    const manHours = ref(props.progressDetail?.man_hours ?? null);
    const quantityFormItem = ref<InstanceType<typeof FormItem> | null>(null);
    const totalQuantity = computed(() => {
      if (quantityFormItem.value?.validationProvider?.flags?.invalid === true) {
        return null;
      }
      return (
        (props.progressHeader.result_quantity ?? 0) - (props.progressDetail?.quantity ?? 0) + (quantity.value ?? 0)
      );
    });
    const handleSubmit = async () => {
      await props.onSubmit({
        start_time: timeRange.value?.startTime!,
        end_time: timeRange.value?.endTime!,
        quantity: quantity.value!,
        man_hours: manHours.value ?? 0,
      });
    };

    return {
      timeRangeRules,
      timeRange,
      isStartDisabled,
      quantity,
      manHours,
      quantityFormItem,
      totalQuantity,
      handleSubmit,
      hasTimetableMasterLogimeterActivities,
    };
  },
});
