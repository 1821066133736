



















import { defineComponent, computed, type PropType } from '@vue/composition-api';
import { addComma } from 'src/filters/number_filters';
import Notification from './components/Notification.vue';

export default defineComponent({
  components: {
    Notification,
  },
  props: {
    timetableMasterName: {
      type: String as PropType<string | null>,
      default: null,
    },
    startTime: {
      type: Number,
      required: true,
    },
    endTime: {
      type: Number,
      required: true,
    },
    headcountFrom: {
      type: Number,
      required: true,
    },
    headcountTo: {
      type: Number,
      required: true,
    },
    toResting: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const message = computed(() => {
      if (!props.toResting) {
        return 'に人数を変更しました。';
      }
      return `に人数を変更し、休憩${props.headcountTo > props.headcountFrom ? 'から' : 'へ'}移動しました。`;
    });
    return {
      message,
      addComma,
    };
  },
});
