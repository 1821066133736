
import Vue from 'vue';
import { computed, defineComponent, onMounted, reactive, SetupContext } from '@vue/composition-api';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { notifyError1 } from 'src/hooks/notificationHook';
import { OauthApplicationResponse } from 'src/models/api/oauthApplicationResponse';
import oauthApplicationApi from 'src/apis/oauth_application';
import { wrappedMapGetters } from 'src/hooks/storeHook';

interface AuthorizeApplicationData {
  client_id: OauthApplicationResponse['uid'];
  redirect_uri: OauthApplicationResponse['redirect_uri'];
  response_type: string; // 'code'
  scope: OauthApplicationResponse['scopes'];
}

interface State {
  userId: number;
  oauthApplicationId: string;
  oauthApplication: OauthApplicationResponse | null;
  showAuthorizeModal: boolean;
  isProcessingResult: boolean;
}

function setupState(root: Vue): State {
  const state: State = reactive({
    userId: wrappedMapGetters(root.$store, 'user', ['id']).id,
    oauthApplicationId: computed(() => root.$route.params.oauthApplicationId),
    oauthApplication: null,
    showAuthorizeModal: false,
    isProcessingResult: false,
  });
  return state;
}

export default defineComponent({
  setup(props, context: SetupContext) {
    const root = context.root as Vue;
    setPageName(root, 'API連携用アプリケーション (Beta版)');
    const state = setupState(root);

    async function getOauthApplication(): Promise<void> {
      state.oauthApplication = await oauthApplicationApi.show(state.oauthApplicationId);
    }

    function backToOauthList(): void {
      root.$router.push({
        name: 'GeneralSettingsOauth',
      });
    }

    function openAuthorizeModal(): void {
      state.showAuthorizeModal = true;
    }

    function closeAuthorizeModal(): void {
      state.showAuthorizeModal = false;
    }

    async function authorizeApplication(): Promise<void> {
      // 二重処理防止
      if (state.isProcessingResult) {
        return;
      }
      state.isProcessingResult = true;

      const authorizeApplicationData: AuthorizeApplicationData = {
        client_id: state.oauthApplication!.uid,
        redirect_uri: state.oauthApplication!.redirect_uri,
        response_type: 'code',
        scope: state.oauthApplication!.scopes,
      };
      let errorMessage = 'API連携用アプリケーションの許可に失敗しました。管理者に連絡してください。';
      try {
        const res = await oauthApplicationApi.authorizeApplication(authorizeApplicationData);
        if (res.status !== 'redirect') {
          // 何か返ってきたがstatusが'redirect'じゃない
          const errorId = 'ERR00002';
          errorMessage += `(ERR: API連携用アプリケーション ${errorId}, user_id:${state.userId})`;
          closeAuthorizeModal();
          notifyError1(root, errorMessage);
          return;
        }
        // callback_urlにリダイレクト
        location.href = res.redirect_uri;
      } catch (error: any) {
        const errorId = 'ERR00001';
        errorMessage += `(ERR: API連携用アプリケーション ${errorId}, user_id:${state.userId})`;
        notifyError1(root, errorMessage, error);
      } finally {
        state.isProcessingResult = false;
      }
    }

    onMounted(async () => {
      await getOauthApplication();
    });

    return {
      state,
      backToOauthList,
      openAuthorizeModal,
      closeAuthorizeModal,
      authorizeApplication,
    };
  },
});
