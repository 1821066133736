import type {
  TimetableRow,
  TimetableTimeBlock,
  ProgressSection,
  BlankProgressSection,
  ActualProgressSection,
  SimulatedProgressSection,
  UnsimulatedProgressSection,
  HourSegment,
  QuarterSegment,
} from '../types';
import { isExist } from 'src/util/isExist';
import { COLUMN_WIDTH, DISPLAY_END_TIME_INTEGER, UNIT_BLOCK_MINUTES_IN_SECONDS } from '../const';
import { secondsToTimeString, timeIntegerToSeconds } from 'src/util/datetime';
import {
  getFlooredIntervalUnitMinutes,
  getNextIntervalHour,
  getNextIntervalUnitMinutes,
} from '../dailySimulationHelper';
import { roundToOneDecimal } from 'src/util/numbers';

export const useProgressSection = () => {
  /**
   * 工程行を区間の配列に変換する
   *  1. 進捗実績を元に実績区間を取得する
   *  2. シミュレーション内容を元にシミュレート・未シミュレート区間を取得する
   *  3. シミュレート済区間が存在する場合、最後の区間が表示終了時間になるように空区間を追加する
   */
  const convertTimetableRowToProgressSections = (timetableRow: TimetableRow): ProgressSection[] => {
    const actualProgressSections = getActualProgressSections(timetableRow);
    const simulatedProgressSections: Array<
      BlankProgressSection | UnsimulatedProgressSection | SimulatedProgressSection
    > = getSimulatedProgressSections(timetableRow);
    if (simulatedProgressSections.length > 0) {
      const endTime = simulatedProgressSections[simulatedProgressSections.length - 1].endTime;
      if (endTime < DISPLAY_END_TIME_INTEGER) {
        simulatedProgressSections.push({
          startTime: endTime,
          endTime: DISPLAY_END_TIME_INTEGER,
          isBlank: true,
        });
      }
    }
    return [...actualProgressSections, ...simulatedProgressSections];
  };

  /**
   * 進捗実績を元に実績区間を取得する
   */
  const getActualProgressSections = (
    timetableRow: TimetableRow,
  ): Array<BlankProgressSection | ActualProgressSection> => {
    let lastActualProgressEndTime = 0;
    const progressSections: Array<BlankProgressSection | ActualProgressSection> = [];
    timetableRow.actualProgresses.forEach((actualProgress) => {
      if (actualProgress.startTime !== lastActualProgressEndTime) {
        progressSections.push({
          startTime: lastActualProgressEndTime,
          endTime: actualProgress.startTime,
          isBlank: true,
        });
      }
      progressSections.push({
        startTime: actualProgress.startTime,
        endTime: actualProgress.endTime,
        isActual: true,
        totalQuantity: actualProgress.totalQuantity,
        productivity: actualProgress.productivity,
      });
      lastActualProgressEndTime = actualProgress.endTime;
    });
    return progressSections;
  };

  /**
   * 実績の終了時間から、シミュレーション完了時間まで（存在すれば）のタイムブロックを元に区間を生成する
   *   1. 範囲内のタイムブロックを取得する
   *   2. タイムブロックを区間の切替条件ごとにグループにまとめる
   *   3. グループごとに区間を生成する
   */
  const getSimulatedProgressSections = (
    timetableRow: TimetableRow,
  ): Array<UnsimulatedProgressSection | SimulatedProgressSection> => {
    const simulatedCompletionTime = timetableRow.header.simulatedCompletionTime;
    const timeBlocks = timetableRow.hourBlocks
      .flatMap((hourBlock) => hourBlock.unitTimeBlocks)
      .filter((timeBlock) => {
        return (
          timetableRow.header.lastActualProgressEndTime <= timeBlock.progress.startTime &&
          (!isExist(simulatedCompletionTime) || timeBlock.progress.endTime <= simulatedCompletionTime)
        );
      });
    const timeBlockGroups = convertTimeBlocksToTimeBlockGroups(timeBlocks);
    const progressSections = convertTimeBlockGroupsToProgressSections(timeBlockGroups);
    return progressSections;
  };

  /**
   * 区間を構成するタイムブロックのグループ
   */
  type TimeBlockGroup = {
    timeBlocks: TimetableTimeBlock[];
    isSimulated: boolean;
  };

  /**
   * タイムブロックの配列を区間の切替条件ごとにグループにまとめる
   */
  const convertTimeBlocksToTimeBlockGroups = (timeBlocks: TimetableTimeBlock[]): TimeBlockGroup[] => {
    return timeBlocks.reduce<TimeBlockGroup[]>((groups, timeBlock, index, array) => {
      const prevBlock = index > 0 ? array[index - 1] : null;
      let shouldStartNewGroup = false;
      if (prevBlock !== null) {
        // 区間切り替え条件:
        // 1. シミュレートフラグが変わったら
        // 2. 生産性(小数第一位まで)が変わったら
        shouldStartNewGroup =
          prevBlock.progress.isSimulated !== timeBlock.progress.isSimulated ||
          roundToOneDecimal(prevBlock.progress.productivity) !== roundToOneDecimal(timeBlock.progress.productivity);
      }
      const lastGroup = groups[groups.length - 1] ?? null;
      if (lastGroup === null || shouldStartNewGroup) {
        groups.push({
          timeBlocks: [timeBlock],
          isSimulated: timeBlock.progress.isSimulated,
        });
      } else {
        lastGroup.timeBlocks.push(timeBlock);
      }
      return groups;
    }, []);
  };

  const convertTimeBlockGroupsToProgressSections = (
    timeBlockGroups: TimeBlockGroup[],
  ): Array<UnsimulatedProgressSection | SimulatedProgressSection> => {
    return timeBlockGroups.reduce<Array<UnsimulatedProgressSection | SimulatedProgressSection>>(
      (sections, timeBlockGroup) => {
        if (timeBlockGroup.timeBlocks.length === 0) {
          return sections;
        }
        const { startTime, endTime, productivity, hourSegments } = getProgressSectionInfo(timeBlockGroup.timeBlocks);
        if (timeBlockGroup.isSimulated) {
          sections.push({
            startTime,
            endTime,
            isSimulated: true,
            productivity,
            hourSegments,
          });
        } else {
          const quarterSegments = hourSegments.flatMap((hourSegment) =>
            hourSegment.quarterSegments.map((quarterSegment) => ({ ...quarterSegment, isLast: false })),
          );
          if (quarterSegments.length > 0) {
            quarterSegments[quarterSegments.length - 1].isLast = true;
          }
          sections.push({
            startTime,
            endTime,
            isSimulated: false,
            productivity,
            quarterSegments,
          });
        }
        return sections;
      },
      [],
    );
  };

  /**
   * 区間を生成するための情報
   */
  type SectionInfo = {
    startTime: number;
    endTime: number;
    productivity: number;
    hourSegments: HourSegment[];
  };

  /**
   * 区間を構成するタイムブロックの配列から、SectionInfoを取得する
   */
  const getProgressSectionInfo = (timeBlocks: TimetableTimeBlock[]): SectionInfo => {
    // 1, 2. 区間の開始時間、終了時間
    const startTime = timeBlocks[0].progress.startTime;
    const endTime = timeBlocks[timeBlocks.length - 1].progress.endTime;
    const startTimeSeconds = timeIntegerToSeconds(startTime);
    const endTimeSeconds = timeIntegerToSeconds(endTime);

    // 3. 生産性
    const productivity = timeBlocks[0].progress.productivity;

    // 4. HourSegmentの配列を生成する
    const hourSegments: HourSegment[] = [];
    let currentHourStartSeconds = startTimeSeconds;
    while (currentHourStartSeconds < endTimeSeconds) {
      // 直後の60分刻みの時間を取得する
      const nextHourSeconds = getNextIntervalHour(currentHourStartSeconds, 'seconds');
      const hourEndSeconds = Math.min(nextHourSeconds, endTimeSeconds);
      const hourDurationSeconds = hourEndSeconds - currentHourStartSeconds;

      // QuarterSegmentの配列を生成する
      const quarterSegments: QuarterSegment[] = [];
      let currentQuarterStartSeconds = currentHourStartSeconds;
      while (currentQuarterStartSeconds < hourEndSeconds) {
        // 直後の15分刻みの時間を取得する
        const nextQuarterSeconds = getNextIntervalUnitMinutes(currentQuarterStartSeconds, 'seconds');
        const quarterEndSeconds = Math.min(nextQuarterSeconds, hourEndSeconds);
        const quarterDurationSeconds = quarterEndSeconds - currentQuarterStartSeconds;
        let displayTimeSeconds = currentQuarterStartSeconds;
        // QuarterSegmentに対応するタイムブロックを取得するために、表示時間を取得する
        // 15分刻みでない場合は、直前の15分刻みに丸める
        if (startTimeSeconds % UNIT_BLOCK_MINUTES_IN_SECONDS !== 0) {
          displayTimeSeconds = getFlooredIntervalUnitMinutes(currentQuarterStartSeconds, 'seconds');
        }
        quarterSegments.push({
          displayTime: secondsToTimeString(displayTimeSeconds),
          durationSeconds: quarterDurationSeconds,
          isLast: false,
        });
        currentQuarterStartSeconds = quarterEndSeconds;
      }

      const totalQuantity =
        timeBlocks.find((timeBlock) => timeIntegerToSeconds(timeBlock.progress.endTime) === hourEndSeconds)?.progress
          ?.totalQuantity ?? 0;
      if (quarterSegments.length > 0) {
        quarterSegments[quarterSegments.length - 1].isLast = true;
      }
      hourSegments.push({
        totalQuantity,
        quarterSegments,
        durationSeconds: hourDurationSeconds,
        isLast: false,
      });
      currentHourStartSeconds = hourEndSeconds;
    }
    if (hourSegments.length > 0) {
      hourSegments[hourSegments.length - 1].isLast = true;
    }
    return { startTime, endTime, productivity, hourSegments };
  };

  /**
   * 区間の集合を1行に表示するためのグリッドスタイルを取得する
   */
  const getProgressSectionsStyle = (progressSections: ProgressSection[]) => {
    if (progressSections.length === 0) {
      return {};
    }
    const gridWidths = progressSections.map((progressSection) => {
      const endTime = timeIntegerToSeconds(progressSection.endTime);
      const startTime = timeIntegerToSeconds(progressSection.startTime);
      return ((endTime - startTime) / (60 * 60)) * COLUMN_WIDTH;
    });
    return {
      gridTemplateColumns: gridWidths.map((item) => `${item}px`).join(' '),
    };
  };

  /**
   * HourSegmentやQuarterSegmentなどの秒数を持つ配列から、その比通りに表示するためのグリッドスタイルを取得する
   */
  const getGridStylesBySegments = (segments: { durationSeconds: number }[]) => {
    return {
      display: 'grid',
      gridTemplateColumns: segments.map((segment) => `${segment.durationSeconds}fr`).join(' '),
    };
  };

  return {
    convertTimetableRowToProgressSections,
    getProgressSectionsStyle,
    getGridStylesBySegments,
  };
};
