import { computed, type ComputedRef } from '@vue/composition-api';
import { formatTimeInteger, timeStrToTimeInteger } from 'src/util/datetime';
import { getNextIntervalUnitMinutes } from '../../dailySimulationHelper';
import { createInjection } from 'src/util/createInjection';
import { useModalWithContext, type ModalWithContext } from 'src/composables/useModalWithContext';
import type { TimetableMaster } from 'src/models/timetableMaster';
import type { ProgressSectionQuarterTime, QuarterSegment } from '../../types';
import QuantityUpdateNotification from '../../components/UpdateNotification/QuantityUpdateNotification.vue';
import { useNotification } from './composables/useNotification';

type ModalContext = {
  event: MouseEvent;
  timetableMaster: TimetableMaster | null;
  quarterSegment: QuarterSegment;
  progressSectionQuarterTime: ProgressSectionQuarterTime;
  initialValue: number | null;
};

type InjectionValue = {
  targetElement: ComputedRef<HTMLElement | null>;
  timetableMaster: ComputedRef<TimetableMaster | null>;
  quarterSegment: ComputedRef<QuarterSegment | null>;
  displayTime: ComputedRef<string>;
  progressSectionQuarterTime: ComputedRef<ProgressSectionQuarterTime>;
  getInitialValue: () => number | null;
  showsQuantityUpdatePopover: ModalWithContext<ModalContext>['showsModal'];
  showQuantityUpdatePopover: ModalWithContext<ModalContext>['showModal'];
  hideQuantityUpdatePopover: ModalWithContext<ModalContext>['hideModal'];
  notifyQuantityUpdate: (quantityTo: number) => void;
};

const { provide, inject } = createInjection<InjectionValue>('useQuantityUpdatePopover');

export function useQuantityUpdatePopoverProvider(): void {
  const {
    context,
    showsModal: showsQuantityUpdatePopover,
    showModal: showQuantityUpdatePopover,
    hideModal: hideQuantityUpdatePopover,
  } = useModalWithContext<ModalContext>();

  const displayTime = computed(() =>
    formatTimeInteger(
      getNextIntervalUnitMinutes(timeStrToTimeInteger(context.value?.quarterSegment.displayTime ?? '')),
    ),
  );

  const { notify } = useNotification(QuantityUpdateNotification);

  const notifyQuantityUpdate = (quantityTo: number) => {
    if (context.value === null) {
      return;
    }
    const endTime = timeStrToTimeInteger(displayTime.value);
    const notificationProps = {
      timetableMasterName: context.value.timetableMaster?.name ?? '',
      quantityTo,
      endTime,
    };
    notify(notificationProps);
  };

  provide({
    targetElement: computed(() =>
      context.value?.event.target instanceof HTMLElement ? context.value?.event.target : null,
    ),
    timetableMaster: computed(() => context.value?.timetableMaster ?? null),
    quarterSegment: computed(() => context.value?.quarterSegment ?? null),
    displayTime,
    progressSectionQuarterTime: computed(
      () => context.value?.progressSectionQuarterTime ?? { start: '', target: '', end: '' },
    ),
    getInitialValue: () => context.value?.initialValue ?? null,
    showsQuantityUpdatePopover,
    showQuantityUpdatePopover,
    hideQuantityUpdatePopover,
    notifyQuantityUpdate,
  });
}

export function useQuantityUpdatePopover(): InjectionValue {
  return inject();
}
