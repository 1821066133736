










































import { ref, computed, watch, defineComponent } from '@vue/composition-api';
import Popover from './components/Popover.vue';
import { useHeadcountUpdatePopover } from '../../composables/UpdatePopover/useHeadcountUpdatePopover';
import { isTimetableTimeBlock } from '../../types';
import { ValidationObserver } from 'vee-validate';
import { PrimaryButton } from 'src/components/UIComponents/Buttons/PrimaryButton';
import { isExist } from 'src/util/isExist';
import { useDailySimulationValidationRules } from '../../composables/useDailySimulationValidationRules';
import NumberInput from './components/NumberInput.vue';

export default defineComponent({
  components: {
    NumberInput,
    PrimaryButton,
    ValidationObserver,
    Popover,
  },
  setup(_, context) {
    const { targetElement, timeBlock, hideHeadcountUpdatePopover, showsHeadcountUpdatePopover, notifyHeadcountUpdate } =
      useHeadcountUpdatePopover();

    const headcountToUpdate = ref<number>(0);

    const { headcountValidationRules } = useDailySimulationValidationRules();

    const isReadyToHeadcountUpdate = computed(() => {
      return headcountToUpdate.value !== timeBlock.value?.headcount;
    });

    const canMoveToResting = computed(() => {
      return isExist(timeBlock.value) && isTimetableTimeBlock(timeBlock.value);
    });

    const updateHeadcount = () => {
      if (!isExist(timeBlock.value)) {
        return;
      }
      notifyHeadcountUpdate(headcountToUpdate.value);
      context.emit('update', headcountToUpdate.value);
      hideHeadcountUpdatePopover();
    };

    const moveToResting = () => {
      if (!isExist(timeBlock.value) || !canMoveToResting.value) {
        return;
      }
      notifyHeadcountUpdate(headcountToUpdate.value, { toResting: true });
      context.emit('moveToResting', headcountToUpdate.value);
      hideHeadcountUpdatePopover();
    };

    watch(targetElement, () => {
      headcountToUpdate.value = timeBlock.value?.headcount ?? 0;
    });

    return {
      targetElement,
      timeBlock,
      headcountToUpdate,
      showsHeadcountUpdatePopover,
      headcountValidationRules,
      isReadyToHeadcountUpdate,
      canMoveToResting,
      hideHeadcountUpdatePopover,
      updateHeadcount,
      moveToResting,
      isExist,
    };
  },
});
