
import { defineComponent, inject, computed } from '@vue/composition-api';
import { StaffWithShiftsStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useShift';
import { PageDisplayStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/usePageDisplay';
import { SHIFT_TOTAL_MODE, STANDARD_WORK_HOURS_PAR_DAY, ATTENDANCE_TYPES_AT_WORK, AttendanceType } from 'src/consts';
import { getTotalBaseValue } from 'src/views/Dashboard/Workplace/RegularShift/components/ShiftTotalTable/libs/getTotalBaseValueHelper';
import { ManHoursStateKey } from '../../../hooks/useManHours';

export default defineComponent({
  name: 'shift-total-cell',
  props: {
    dt: {
      type: String,
      required: true,
    },
    macroOperationMasterId: {
      type: Number,
      required: false,
    },
  },

  setup(props, context) {
    const staffWithShiftsState = inject(StaffWithShiftsStateKey);
    const pageDisplayState = inject(PageDisplayStateKey);
    const manHoursState = inject(ManHoursStateKey);
    if (!staffWithShiftsState || !pageDisplayState || !manHoursState) {
      throw new Error('State error');
    }

    const { macroOperationSegmentMasters, staffsWithShifts } = staffWithShiftsState;

    const { shiftManHoursByMacroOperation, totalShiftManHoursByDate } = manHoursState;

    const { shiftTotalMode } = pageDisplayState;

    const manHours = computed(() => {
      const totalManHoursMapByDate =
        props.macroOperationMasterId === undefined
          ? totalShiftManHoursByDate.value
          : shiftManHoursByMacroOperation.value[props.macroOperationMasterId];

      if (totalManHoursMapByDate === undefined) {
        return 0;
      }

      return Math.round(Number(totalManHoursMapByDate[props.dt]));
    });

    const shiftBaseValue = computed(() => {
      const idMatchedMacroOperationSegmentMasters = props.macroOperationMasterId
        ? macroOperationSegmentMasters.value.filter((e) => {
            return e.macro_operation_master_id === props.macroOperationMasterId;
          })
        : macroOperationSegmentMasters.value.slice();
      return getTotalBaseValue(props.dt, idMatchedMacroOperationSegmentMasters);
    });

    const manHoursDifference = computed(() => {
      return manHours.value - shiftBaseValue.value;
    });

    const attendantsCount = computed(() => {
      return props.macroOperationMasterId === undefined
        ? getTotalAttendantsCount()
        : getAttendantsCountByMacroOperationMaster();
    });

    const differenceCellClass = computed(() => (manHoursDifference.value >= 0 ? 'positive-value' : 'negative-value'));

    // 全体の出勤人数を計算
    const getTotalAttendantsCount = () => {
      return staffsWithShifts.value.reduce((count, staffWithShifts) => {
        const shift = staffWithShifts.shifts.find((e) => e.date === props.dt);
        if (shift === undefined) {
          return count;
        }

        return (ATTENDANCE_TYPES_AT_WORK as AttendanceType[]).includes(shift.attendanceType) ? count + 1 : count;
      }, 0);
    };

    // シフトグループごとの出勤人数を計算
    const getAttendantsCountByMacroOperationMaster = () => {
      return staffsWithShifts.value.reduce((count, staffWithShifts) => {
        if (staffWithShifts.staff_extension.macro_operation_master.id !== props.macroOperationMasterId) {
          return count;
        }
        const shift = staffWithShifts.shifts.find((e) => e.date === props.dt);
        if (shift === undefined) {
          return count;
        }

        return (ATTENDANCE_TYPES_AT_WORK as AttendanceType[]).includes(shift.attendanceType) ? count + 1 : count;
      }, 0);
    };

    return {
      manHoursDifference,
      shiftTotalMode,
      shiftBaseValue,
      SHIFT_TOTAL_MODE,
      STANDARD_WORK_HOURS_PAR_DAY,
      manHours,
      attendantsCount,
      differenceCellClass,
    };
  },
});
