

































import { defineComponent, ref, watch } from '@vue/composition-api';
import { ValidationObserver, ValidationObserverInstance } from 'vee-validate';
import { PrimaryButton } from 'src/components/UIComponents/Buttons/PrimaryButton';
import Popover from './components/Popover.vue';
import NumberInput from './components/NumberInput.vue';
import { useQuantityUpdatePopover } from '../../composables/UpdatePopover/useQuantityUpdatePopover';
import { useDailySimulationValidationRules } from '../../composables/useDailySimulationValidationRules';

export default defineComponent({
  components: {
    NumberInput,
    PrimaryButton,
    ValidationObserver,
    Popover,
  },
  setup(_, context) {
    const {
      targetElement,
      timetableMaster,
      displayTime,
      getInitialValue,
      hideQuantityUpdatePopover,
      showsQuantityUpdatePopover,
      notifyQuantityUpdate,
    } = useQuantityUpdatePopover();

    const quantityToUpdate = ref<number | null>(null);

    const { quantityValidationRules } = useDailySimulationValidationRules();

    const observer = ref<ValidationObserverInstance>();

    // 数値が入っている数量更新ポップオーバーが表示されている状態で、区間の末尾でない時間帯で数量更新ポップオーバーを表示すると
    // バリデーションが走りエラーが出てしまうが、これを抑制するためリセットする。
    // quantityToUpdateの状態変更によるバリデーション実行を適切に待機する必要があるため、非同期で実行する。
    // nextTickでは複数回繰り返さないとタイミングが合わず、setTimeoutであれば確実であるためこちらを採用する。
    watch([timetableMaster, displayTime], () => {
      setTimeout(() => {
        if (observer.value) {
          observer.value.reset();
        }
      });
    });

    const updateQuantity = async () => {
      if (observer.value === undefined) {
        return;
      }
      // 数量指定ポップオーバーは他のポップオーバーと異なり、初期値が空である場合がある
      // 初期状態ではエラーとみなさないため、設定ボタンが押された時点でバリデーションを行う
      const isValid = await observer.value.validate();
      if (!isValid || quantityToUpdate.value === null) {
        return;
      }
      notifyQuantityUpdate(quantityToUpdate.value);
      context.emit('update', quantityToUpdate.value);
      hideQuantityUpdatePopover();
    };

    watch(targetElement, () => {
      quantityToUpdate.value = getInitialValue();
    });

    return {
      targetElement,
      displayTime,
      quantityToUpdate,
      showsQuantityUpdatePopover,
      quantityValidationRules,
      hideQuantityUpdatePopover,
      updateQuantity,
      observer,
    };
  },
});
