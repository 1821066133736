































































import { defineComponent, type PropType, computed } from '@vue/composition-api';
import { type ActivityStatusMap } from 'src/models/currentStaffWork';
import { orDefault } from 'src/util/filters';
import { formatNumber } from '../utils/filters';

export default defineComponent({
  filters: {
    orDefault,
    formatNumber,
  },
  props: {
    activityStatusMap: {
      type: Object as PropType<ActivityStatusMap>,
      required: true,
    },
    showStatusPanel: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const rescueStaffWorkingCount = computed(() => {
      const rescueStaffHeadCounts = props.activityStatusMap.rescue_staff_head_counts;
      if (rescueStaffHeadCounts.working === null && rescueStaffHeadCounts.other_working === null) {
        return null;
      }
      return (rescueStaffHeadCounts.working ?? 0) + (rescueStaffHeadCounts.other_working ?? 0);
    });

    const totalWorkingCount = computed(() => {
      const ourStaffWorkingCount = props.activityStatusMap.our_staff_head_counts.working;
      const ourStaffOtherWorkingCount = props.activityStatusMap.our_staff_head_counts.other_working;
      if (
        ourStaffWorkingCount === null &&
        ourStaffOtherWorkingCount === null &&
        rescueStaffWorkingCount.value === null
      ) {
        return null;
      }
      return (ourStaffWorkingCount ?? 0) + (ourStaffOtherWorkingCount ?? 0) + (rescueStaffWorkingCount.value ?? 0);
    });

    const rescueStaffRestingCount = computed(() => {
      return props.activityStatusMap.rescue_staff_head_counts.resting;
    });

    const totalRestingCount = computed(() => {
      const ourStaffRestingCount = props.activityStatusMap.our_staff_head_counts.resting;
      if (ourStaffRestingCount === null && rescueStaffRestingCount.value === null) {
        return null;
      }
      return (ourStaffRestingCount ?? 0) + (rescueStaffRestingCount.value ?? 0);
    });

    const rescueStaffWaitingCount = computed(() => {
      return props.activityStatusMap.rescue_staff_head_counts.waiting;
    });

    const totalWaitingCount = computed(() => {
      const ourStaffWaitingCounts = props.activityStatusMap.our_staff_head_counts.waiting;
      if (ourStaffWaitingCounts === null && rescueStaffWaitingCount.value === null) {
        return null;
      }
      return (ourStaffWaitingCounts ?? 0) + (rescueStaffWaitingCount.value ?? 0);
    });

    return {
      totalWorkingCount,
      totalRestingCount,
      totalWaitingCount,
      rescueStaffWorkingCount,
      rescueStaffRestingCount,
      rescueStaffWaitingCount,
    };
  },
});
