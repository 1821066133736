import { reactive } from '@vue/composition-api';
import { createInjection } from 'src/util/createInjection';
import { MonthlyPlanOverviewState, MonthlyPlanState } from '../types';
import { isExist } from 'src/util/isExist';

type InjectionValue = {
  state: MonthlyPlanOverviewState;
  updateOverviewState: (monthlyPlanState: MonthlyPlanState) => void;
};

const { provide, inject } = createInjection<InjectionValue>('useMonthlyPlanOverview');

export function useMonthlyPlanOverviewProvider(): void {
  const state: MonthlyPlanOverviewState = reactive({
    targetMonthlyProfit: 0,
    totalActualRevenue: 0,
    totalActualRevenuePercent: null,
    totalActualCost: 0,
    totalActualCostPercent: null,
    totalActualProfit: 0,
    totalActualProfitPercent: null,
    tasks: [],
  });

  const updateOverviewState = (monthlyPlanState: MonthlyPlanState) => {
    const created = createOverview(monthlyPlanState);
    state.targetMonthlyProfit = created.targetMonthlyProfit;
    state.totalActualRevenue = created.totalActualRevenue;
    state.totalActualCost = created.totalActualCost;
    state.totalActualProfit = created.totalActualProfit;
    state.totalActualRevenuePercent = created.totalActualRevenuePercent;
    state.totalActualCostPercent = created.totalActualCostPercent;
    state.totalActualProfitPercent = created.totalActualProfitPercent;
    state.tasks = created.tasks;
  };

  const createOverview = (monthlyPlanState: MonthlyPlanState): MonthlyPlanOverviewState => {
    let totalActualRevenue = 0;
    let totalActualCost = 0;
    const tasks = new Array(monthlyPlanState.tasks.length).fill(0).map(() => {
      return {
        totalRequiredManHours: 0,
        totalActualManHours: 0,
        totalOvertimeWorkHours: 0,
        totalExpectedManHours: 0,
      };
    });
    monthlyPlanState.dailyPlans.forEach((plan) => {
      const { actualRevenue, actualCost } = plan;
      totalActualRevenue += actualRevenue ?? 0;
      totalActualCost += actualCost ?? 0;
      plan.tasks.forEach((task, i) => {
        tasks[i].totalRequiredManHours += task.requiredManHours;
        tasks[i].totalActualManHours += task.actualManHours ?? 0;
        tasks[i].totalOvertimeWorkHours += task.overtimeWorkHours ?? 0;
        tasks[i].totalExpectedManHours += task.actualManHours ?? task.requiredManHours;
      });
    });
    const targetMonthlyProfit =
      (monthlyPlanState.targetMonthlyRevenue ?? 0) - (monthlyPlanState.targetMonthlyCost ?? 0);
    const totalActualProfit = totalActualRevenue - totalActualCost;
    return {
      targetMonthlyProfit,
      totalActualRevenue,
      totalActualCost,
      totalActualProfit,
      totalActualRevenuePercent:
        isExist(monthlyPlanState.targetMonthlyRevenue) && monthlyPlanState.targetMonthlyRevenue > 0
          ? totalActualRevenue / monthlyPlanState.targetMonthlyRevenue / 10
          : null,
      totalActualCostPercent:
        isExist(monthlyPlanState.targetMonthlyCost) && monthlyPlanState.targetMonthlyCost > 0
          ? totalActualCost / monthlyPlanState.targetMonthlyCost / 10
          : null,
      totalActualProfitPercent: targetMonthlyProfit > 0 ? totalActualProfit / targetMonthlyProfit / 10 : null,
      tasks,
    };
  };

  provide({
    state,
    updateOverviewState,
  });
}

export function useMonthlyPlanOverview(): InjectionValue {
  return inject();
}
